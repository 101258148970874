<template>
  <div class="cheque">
    <div class="cheque__field">
      От:
      <div class="cheque__field-name">{{ performer }}</div>
    </div>
    <div class="cheque__field">
      К:
      <div class="cheque__field-name">{{ payer }}</div>
    </div>
    <div class="cheque__field">
      На сумму:
      <div class="cheque__field-name cheque__field-name_gold">{{ cheque.sum || 0 }} ₽</div>
    </div>
    <Btn :small="true" class="cheque__paid" v-if="(profile.log == performer) && (!cheque.paid)" @click="setAsPaid">Отметить как оплаченный</Btn>
    <div class="cheque__status" v-else>{{ cheque.paid ? 'Оплачен' : 'Не оплачен' }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Btn from '@/components/buttons/Btn.vue'
export default {
  components: { Btn },
  props: {
    cheque: { type: Object, required: true }
  },
  computed: {
    ...mapState(['profile','workspace','url']),
    ...mapGetters(['userMap']),
    performer() {
      return this.userMap[this.cheque.performer]?.log
    },
    payer() {
      return this.userMap[this.cheque.payer]?.log
    },
  },
  methods: {
    async setAsPaid() {
      if (!confirm(`Пометить чек на ${this.cheque.sum} от ${this.performer} к ${this.payer} как оплаченный?`))
        return;
      await fetch(this.url + 'cheque', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify({
          id: this.cheque._id,
          ws: this.workspace._id,
          payer: this.cheque.payer,
        })
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$emit('update')
      }).catch(err => {
        alert(err); console.error(err)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.cheque
  white-space: nowrap
  min-width: fit-content
  width: fit-content
  max-width: fit-content
  max-height: min-content
  display: flex 
  flex-direction: column
  gap: 10px
  background: colors.$transi
  padding: 10px
  border-radius: 10px
  color: white
  &__status
    text-align: center
    min-width: 200px
    background: colors.$transi
    padding: 10px 20px
    border-radius: 10px
    font-weight: 700
    user-select: none
  &__field
    display: flex
    justify-content: space-between
    gap: 20px
    &-name
      font-weight: 500
      color: colors.$lightgreen
      &_gold
        color: colors.$gold
  &__paid
    padding: 8px 12px
    width: 100%
</style>