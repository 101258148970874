<template>
  <div class="custom-select" :class="{'custom-select_expanded':dropdownVisible}">
    <div class="custom-select-picker" tabindex="0" @click="toggleDropdown" @keydown.space.prevent="toggleDropdown" @keydown.enter.prevent="toggleDropdown">
      <ImagedThing class="selected-option" v-if="!selectedOptions.length">
        <img :src="require('@/assets/img/profile.svg')" v-if="selectedOptions.length">
        <span class="dimtext">{{ tooltext }}</span>
      </ImagedThing>
      <ImagedThing class="selected-option" v-for="el in selectedOptions" :key="el._id" v-else>
        <div class="selected-option-img">
          <img :src="el.ava ? (url + el.ava) : require('@/assets/img/profile.svg')">
      </div>
        <div>{{ el.log }}</div>
      </ImagedThing>
    </div>
    <div class="options-dropdown" v-show="dropdownVisible">
      <ImagedThing tag="label" v-for="(option, index) in options" :key="index" class="option-preview" tabindex="0">
        <input type="checkbox" :id="`option-${option._id}`" :value="option._id" v-model="temporaryValue" @change="handleSelectionChange">
        <img :src="option.ava ? (url + option.ava) : require('@/assets/img/profile.svg')" :alt="option.log" class="option-image">
        <span>{{ option.log }}</span>
      </ImagedThing>
      <button class="select-btn hovergreen" @click="applySelections">Выбрать</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImagedThing from './ImagedThing.vue';

export default {
  name: 'SelectMultiple',
  props: {
    tooltext: {
      type: String, default: 'Select options'
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      selectedValue: this.modelValue,
      temporaryValue: [...this.modelValue], // Temporary array for selections
      dropdownVisible: false,
    };
  },
  computed: {
    ...mapState(['url']),
    selectedOptions() {
      return this.options.filter(option => this.selectedValue.includes(option._id));
    },
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
      this.temporaryValue = [...newValue]; // Update temporaryValue when modelValue changes from outside
    },
  },
  methods: {
    handleSelectionChange() {
      // Now this method only needs to update temporaryValue
      // The actual updating of selectedValue is handled in applySelections
    },
    applySelections() {
      this.selectedValue = [...this.temporaryValue];
      this.$emit('update:modelValue', this.selectedValue);
      this.dropdownVisible = false;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
  },
  components: { ImagedThing }
}
</script>

<style lang="sass">

.options-dropdown
  position: absolute
  width: 100%
  cursor: pointer
  display: flex
  flex-direction: column
  background: colors.$darkgreen
  border-radius: 0px 0px 10px 10px 
  overflow: hidden
  
  >*
    border-radius: 0px !important

.custom-select-picker
  display: flex
  width: 100%
  overflow: hidden
  &:has(>*:nth-child(3))
    .imagedthing
      flex-direction: column
      .selected-option-img
        width: 40px
  >*
    flex-grow: 1
    display: flex
    &:not(:last-child)
      border-right: 1px solid colors.$green2
      border-radius: 10px 0 0 10px !important
    &:not(:first-child)
      border-radius: 0 10px 10px 0 !important
    &:not(:first-child):not(:last-child)
      border-radius: 0px !important
    .selected-option-img
      position: relative
      flex-basis: 40px
      flex-shrink: 1
      height: 40px
      img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%


.custom-select
  position: relative
  width: 100%
  z-index: 2
  border-radius: 10px
  &_expanded
    .custom-select-picker .imagedthing
      border-radius: 10px 10px 0px 0px
    .imagedthing.selected-option
      border-bottom-left-radius: 0px !important
      border-bottom-right-radius: 0px !important
  .select-btn
    line-height: 1.286
    width: 100%
    background: colors.$darkgreen
    color: white
    cursor: pointer
    white-space: nowrap
    border-radius: 10px
    transition: background .1s ease-out 
    padding: 10px
    font-size: 14px
    font-weight: 500  
  .hidden-select
    position: absolute
    pointer-events: none
    opacity: 0
    height: 0
    width: 0
  .selected-option
    cursor: pointer

  .option-preview
    background: colors.$darkgreen
    input
      position: absolute
      pointer-events: none
      opacity: 0
    &:has(input:checked)
      background: colors.$darkgreen2
    &:hover, &:focus-visible
      background: colors.$green2 !important
</style>
