<template>
  <div v-if="workspace" class="workspace">
    <div class="workspace__header">
      <h1 class="workspace__name">{{ workspace.name }}</h1>
      <div class="workspace__buttons" v-if="mode == 0">
        <ManageWorkspace v-if="localRole == 'director'" />
        <PerformerFilter v-if="['director', 'manager'].includes(localRole)" />
        <TagFilter />
        <AddUsers v-if="localRole == 'director'" />
        <Btn :small="true" @click="mode = 1" class="workspace__tochecks" title="К чекам">
          <svg viewBox="0 0 24 24" fill="#000000"><g id="cheque"> <rect class="cls-1" x="1.5" y="4.36" width="21" height="15.27"></rect> <path class="cls-1" d="M5.32,14.86H7.7a1.43,1.43,0,0,0,1.44-1.43h0A1.43,1.43,0,0,0,7.7,12h-1a1.43,1.43,0,0,1-1.43-1.43h0A1.43,1.43,0,0,1,6.75,9.14H9.14"></path> <line class="cls-1" x1="7.23" y1="7.23" x2="7.23" y2="9.14"></line> <line class="cls-1" x1="7.23" y1="16.77" x2="7.23" y2="14.86"></line> <line class="cls-1" x1="12.95" y1="8.18" x2="18.68" y2="8.18"></line> <line class="cls-1" x1="12.95" y1="12" x2="18.68" y2="12"></line> <line class="cls-1" x1="12.95" y1="15.82" x2="18.68" y2="15.82"></line> </g></svg>
        </Btn>
      </div>
      <div class="workspace__buttons" v-if="mode == 1">
        <Btn :small="true" @click="mode = 0" class="workspace__tochecks">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 386.242 386.242" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path id="Arrow_Back" d="M374.212,182.3H39.432l100.152-99.767c4.704-4.704,4.704-12.319,0-17.011 c-4.704-4.704-12.319-4.704-17.011,0L3.474,184.61c-4.632,4.632-4.632,12.379,0,17.011l119.1,119.1 c4.704,4.704,12.319,4.704,17.011,0c4.704-4.704,4.704-12.319,0-17.011L39.432,206.36h334.779c6.641,0,12.03-5.39,12.03-12.03 S380.852,182.3,374.212,182.3z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
        </Btn>
      </div>
    </div>
    <div class="line"></div>
    <div class="workspace__content" v-if="mode == 0">
      <transition-group class="workspace__content2" name="column-transition" tag="div" @dragover.prevent="dragOver" v-if="(workspace.order.length == columns.length) && columns.length">
        <Column @drop.stop="drop($event, c._id)" @dragstarted="startDrag" v-for="(c, index) in orderedColumns" :key="c._id" :column="c" @dragstart="dragStart(index, $event)" :draggable="dragstarted" />
      </transition-group>
      <ColumnCreator v-if="localRole == 'director'" />
    </div>
    <div class="workspace__content-checks" v-if="mode == 1">
      <Cheques />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ColumnCreator from './ColumnCreator.vue';
import Column from './Column.vue';
import AddUsers from './AddUsers.vue';
import TagFilter from './TagFilter.vue';
import PerformerFilter from './PerformerFilter.vue';
import ManageWorkspace from './ManageWorkspace.vue';
import Btn from './buttons/Btn.vue';
import Cheques from './Cheques.vue';

export default {
  data: () => ({
    mode: 0,
    order: [],
    moveorder: [],
    timer: null,
    clientX: 0,
    dragstarted: false,
    i: 0,
    j: 0,
    drag: false
  }),
  computed: {
    ...mapState(['workspace', 'columns', 'url', 'Role', 'localRole']),
    ...mapGetters(['columnMap', 'reverseColumnMap']),
    orderedColumns() {
      let res = []
      for (let i of (this.drag ? this.moveorder : this.order))
        res.push(this.columns[i])
      return res
    }
  },
  methods: {
    startDrag() {
      if (this.localRole == 'director')
        this.dragstarted = true
      else
        this.dragstarted = false
    },
    log() {
      console.log(this.columns)
      console.log(this.workspace.order)
    },
    dragStart(index, event) {
      var transparentImg = new Image();
      transparentImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='; // A tiny, transparent GIF

      // Set the custom drag image - position it far off screen
      event.dataTransfer.setDragImage(transparentImg, -10, -10);
      this.moveorder = [...this.order]
      this.i = [...event.target.parentElement.children].indexOf(event.target)
      this.j = this.i
      this.timer = setInterval(() => {
        let j = this.getHoveredColumnIndex(this.clientX);
        if (j != this.j) {
          this.j = j
          let temp = [...this.order]
          const element = temp.splice(this.i, 1)[0]
          temp.splice(j, 0, element)
          //eslint-disable-next-line
          this.moveorder = temp
        }
      }, 50);
      this.drag = true

      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/plain', index);
    },
    dragOver(e) {
      this.clientX = e.clientX || e.touches[0].clientX
      e.preventDefault();
    },
    async onTaskDrop(e, cid) {
      const data = JSON.parse(e.dataTransfer.getData('application/json'))
      const tk = data.task_id
      const col = cid

      await fetch(this.url + 'task', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify({ ws: this.$store.state.workspace._id, tk, col })
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getTasks')
      })
        .catch(err => console.error(err))
      //const sourceColumnId = data.sourceColumnId
      // Dispatch Vuex action to move task to new column
      //this.$store.dispatch('moveTaskToColumn', { taskId, sourceColumnId, targetColumnId });
      // Optionally, trigger any re-fetching or updating of local component state
    },
    drop(event, cid) {
      const i = parseInt(event.dataTransfer.getData('text/plain'));
      if (isNaN(i))
        return this.onTaskDrop(event, cid)
      const j = this.getHoveredColumnIndex(event.clientX);
      const element = this.order.splice(i, 1)[0]
      this.order.splice(j, 0, element)
      //eslint-disable-next-line
      this.order = this.order
      this.drag = false
      clearInterval(this.timer)
      this.setOrder()
      this.dragstarted = false
      /*
      if (fromIndex !== toIndex) {
        const itemToMove = this.columns.splice(fromIndex, 1)[0];
        this.columns.splice(toIndex, 0, itemToMove);
        this.setColumns([...this.columns]); // Update Vuex state
      }
      */
    },
    getHoveredColumnIndex(clientX) {
      // Calculate drop index based on the horizontal mouse position
      const containerOffset = this.$el.querySelector('.workspace__content').getBoundingClientRect().left + window.pageXOffset;
      const relativeX = clientX - containerOffset;
      const columnIndex = Math.floor((relativeX) / (300 + 15)); // 300px width + 15px gap
      return Math.max(0, Math.min(this.columns.length, columnIndex));
    },
    updateOrder() {
      if (this.workspace) {
        this.order = this.workspace.order.map(el => this.columnMap[el] || 0)
        this.moveorder = this.order
      }
    },
    setOrder() {
      let o = this.order.map(el => this.reverseColumnMap[el] || 0)
      if (o.find(el => !el))
        return console.error('wrong order: ' + o)

      fetch(this.url + 'workspace', {
        credentials: 'include',
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ws: this.workspace._id, order: o })
      }).then(async (res) => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getWorkspace', { id: this.workspace._id, silent: true })
      }).catch(err => {
        console.error(err)
        alert(err)
      })
    }
  },
  components: { ColumnCreator, Column, AddUsers, TagFilter, PerformerFilter, ManageWorkspace, Btn, Cheques },
  watch: {
    workspace() {
      this.updateOrder()
      this.mode = 0
    },
    columns: {
      handler() {
        this.updateOrder()
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass">
.column-transition-move, .column-transition-move
  transition: transform .5s ease
  
.workspace
  display: flex
  flex-direction: column
  //min-height: calc(100vh - var(--pad) * 2)
  //min-height: calc(100svh - var(--pad) * 2)
  &__tochecks
    svg
      height: 29px
      width: 29px
      stroke: black
      stroke-width: 1
  &__back
    svg
      height: 29px
      stroke: black
      stroke-width: 1
    
  &__buttons
    display: flex
    align-items: center
    gap: 15px
    margin-left: auto
  &__header
    position: relative
    z-index: 20
    display: flex
    align-items: flex-end
    height: 50px
  &__name
    font-size: 40px
    color: colors.$lighest
    font-weight: 700
    line-height: 1
    text-shadow: -1px -1px 0 colors.$darkgreen2, 1px -1px 0 colors.$darkgreen2, -1px 1px 0 colors.$darkgreen2, 1px 1px 0 colors.$darkgreen2
    
  &__content
    flex-grow: 1
    &-checks
      flex-grow: 1
      flex-shrink: 0
      flex-direction: column
      display: flex

  &__content, &__content2
    display: flex
    gap: 15px
</style>