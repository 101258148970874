<template>
  <h3 class="radiogroup__title" v-if="title">{{ title }}</h3>
  <div class="radiogroup" v-bind="$attrs">
    <label class="radio" v-for="option in options" :key="option">
      <input :required="required?'required':null" :disabled="disabled" type="radio" v-model="val" :value="option?.value || option" :name="name">
      <span>{{ option?.text || option }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    modelValue: { type: String },
    options: { type: Array },
    name: {type: String},
    title: {type: String},
  },
  computed: {
    val:{
      get()  {
        return this.modelValue
      },
      set(v){
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>

<style lang="sass">
.radiogroup
  display: grid
  gap: 20px
  grid-template-columns: repeat(auto-fill, minmax(min-content, 1px))
  &__title
    font-weight: 500
    font-size: 20px
    margin-bottom: 5px
  &_2
    grid-template-columns: minmax(min-content,1fr) minmax(min-content,1fr)
.radio 
  display: flex
  align-items: center
  gap: 5px
  input
    margin-top: 3px
    align-self: flex-start

</style>