<template>
  <SelectText class="tagfilter" v-model="tag" tooltext="Фильтр по тегу" :options="globalTags"/>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectText from './ui/SelectText.vue';
export default {
  components: { SelectText },
  computed: {
    ...mapGetters(['globalTags']),
    tag:{
      get(){
        return this.$store.state.tag
      },
      set(v){
        this.$store.commit('setTag', v)
      }
    }
  },
  watch: {
    globalTags(v) {
      if (!this.tag)
        return
      if (!v?.length || !v.includes(this.tag))
        this.tag = null
    }
  }
}
</script>

<style lang="sass">
.tagfilter
  margin-left: auto
  width: fit-content
  text-align: center
  min-width: fit-content
  >.imagedthing
    background: colors.$darkdark !important
    transition: background .1s ease-out !important
    box-shadow: 0px 3px 5px rgba(0,0,0,.3) !important
    &:hover
      background: colors.$darkgreen2 !important

  .options-dropdown
    overflow: visible !important
    height: 0 !important
    position: static !important
    .imagedthing
      &:last-child
        border-radius: 0 0 10px 10px !important

  .imagedthing
    min-width: fit-content
    white-space: nowrap
    min-height: 39.19px !important
    height: 39.19px !important
    
      
</style>