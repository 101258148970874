<template>
  <div class="square addworkspacesquare" @click="createWorkspace" title="Создать воркспейс">
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M6 12H18M12 6V18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  methods: {
    async createWorkspace() {
      const name = prompt('Укажите имя воркспейса')
      this.$store.commit('toggleLoad', 'createWorkspace')
      await fetch(this.url + 'workspace', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      }).then(res => {
        if (!res.ok)
          throw (res.status)
        return res.json()
      }).then(async id => {
        await this.$store.dispatch('getWlist')
        const el = this.wlist.find(el => el.id == id)
        if (el)
          this.$store.dispatch('getWorkspace', { id })
      })
        .catch(err => console.error(err))
      this.$store.commit('toggleLoad', 'createWorkspace')
    }
  },
  computed: {
    ...mapState(['url', 'wlist'])
  }
}
</script>

<style lang="sass" scoped>
.addworkspacesquare
  svg
    width: 40px
    height: 40px
    stroke: colors.$lightgreen

</style>