<template>
  <div class="fileInput" :class="{'fileInput_mini':mini}">
    <div class="fileInput__files">
      <button @click="active = true" class="fileInput__attach" v-if="!disabled">
        <svg viewBox="0 0 91 91" enable-background="new 0 0 91 91" id="Layer_1" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="var(--color1)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M88.3,34.924c-1.304-1.305-3.419-1.305-4.724,0L40.378,78.117c-3.561,3.561-8.238,5.568-13.175,5.652 c-4.922,0.104-9.454-1.732-12.843-5.121c-7.027-7.027-6.789-18.701,0.529-26.02c0.01-0.01,0.016-0.021,0.026-0.032l41.019-41.016 c4.5-4.492,11.817-4.49,16.319,0.009c4.503,4.504,4.503,11.832,0,16.336c-0.005,0.005-0.008,0.011-0.013,0.016L30.501,69.68 c-0.002,0.002-0.002,0.003-0.004,0.005c-1.873,1.865-4.92,1.864-6.789-0.005c-0.908-0.906-1.41-2.113-1.41-3.396 c0-1.281,0.498-2.482,1.4-3.388c0.005-0.004,0.01-0.007,0.014-0.011l37.43-37.438c1.304-1.305,1.304-3.418-0.001-4.723 s-3.419-1.305-4.724,0l-37.41,37.418c-0.007,0.007-0.016,0.011-0.021,0.018c-2.172,2.168-3.367,5.055-3.367,8.123 s1.195,5.951,3.369,8.125c2.238,2.234,5.179,3.352,8.118,3.352c2.94-0.002,5.881-1.117,8.118-3.354 c0.005-0.005,0.008-0.011,0.014-0.016l41.736-41.735c0.001-0.002,0.003-0.003,0.005-0.005c0.003-0.003,0.006-0.007,0.009-0.01 l0.711-0.711c0.182-0.181,0.331-0.38,0.462-0.588c5.896-7.155,5.505-17.787-1.183-24.477c-6.69-6.686-17.318-7.074-24.469-1.181 c-0.208,0.131-0.407,0.281-0.588,0.462L10.163,47.904c-0.01,0.009-0.015,0.02-0.023,0.029C0.243,57.859,0.017,73.748,9.638,83.371 c4.578,4.578,10.683,7.084,17.235,7.084c0.148,0,0.297-0.004,0.445-0.006c6.677-0.113,12.993-2.816,17.784-7.609 c0.004-0.003,0.006-0.008,0.009-0.011L88.3,39.646C89.604,38.344,89.604,36.229,88.3,34.924z"></path> </g> </g></svg>
      </button>
      <div class="fileInput__file" v-for="file,index in modelValue" :key="index + ' ' + file.name + ' ' + file.type">
        <a class="file" :href="file?.link" target="_blank">
          <CloseBtn class="file__delete" v-if="!disabled" @click.stop.prevent="()=>deleteFile(index)" />
          <div class="file__icon">
            <svg v-if="file?.type == 'image'" viewBox="0 0 20 20" fill="none">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path fill-rule="evenodd" d="M2 3a2 2 0 012-2h7.586A2 2 0 0113 1.586L17.414 6A2 2 0 0118 7.414V17a2 2 0 01-2 2H4a2 2 0 01-2-2V3zm9.586 0H4v7.612l2.36-2.325.67-.658.7.626 2.201 1.97 1.933-1.932.672-.672.706.637L16 10.749V7.414L11.586 3zM16 13.444l-3.393-3.065-1.183 1.183L16 15.658v-2.214zM4 17v-3.581l3.095-3.048L14.501 17H4z"></path>
              </g>
            </svg>
            <svg v-else viewBox="0 0 20 20" fill="none">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path fill-rule="evenodd" d="M4 1a2 2 0 00-2 2v14a2 2 0 002 2h12a2 2 0 002-2V7.414A2 2 0 0017.414 6L13 1.586A2 2 0 0011.586 1H4zm0 2h7.586L16 7.414V17H4V3zm2 2a1 1 0 000 2h5a1 1 0 100-2H6zm-1 5a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h8a1 1 0 100-2H6z"></path>
              </g>
            </svg>
          </div>
          <div class="file__name">{{ file?.name }}</div>
        </a>
      </div>
    </div>
  </div>
  <Popup v-model="active" v-if="!disabled" >
    <form ref="form">
      <TextInput required class="mb10" v-model="newfile.name" placeholder="Имя"/>    
      <TextInput required class="mb10" v-model="newfile.link" placeholder="Ссылка на файл"/>    
      <RadioGroup :required="true" title="Тип" name="type" class="mb10 radiogroup_2" :options="[{ value: 'document', text: 'Документ' }, { value: 'image', text: 'Изображение' }]" v-model="newfile.type" />

      <Btn class="w100" @click.prevent.stop="add">
        <span>Добавить</span>
      </Btn>
    </form>
  </Popup>

</template>

<script>
import Popup from './Popup.vue';
import RadioGroup from './RadioGroup.vue';
import Btn from '../buttons/Btn.vue';
import TextInput from './TextInput.vue';
import CloseBtn from '../buttons/closeBtn.vue';
export default {
  data: ()=>({
    active: false,
    newfile: {
      name: '',
      link: '',
      type: ''
    }
  }),
  props: {
    modelValue: { type: Array, required: true },
    disabled: {type: Boolean, default: false},
    mini: {type: Boolean, default: false},
  },
  components: { Popup, Btn, RadioGroup, TextInput, CloseBtn },
  methods: {
    deleteFile(index) {
      this.$emit('update:modelValue', this.modelValue.filter((el,i) => i!=index))
    },
    add(){
      let form = this.$refs.form
      if (!form.checkValidity()) {
        let b = document.createElement('button')
        form.appendChild(b)
        b.click()
        b.remove()
      }
      if (!this.newfile.link || !this.newfile.type || !this.newfile.name)
        return
      let updated =  [...this.modelValue, {...this.newfile}]
      this.$emit('update:modelValue', updated)
      this.newfile.name = ''
      this.newfile.link = ''
      this.newfile.type = ''
      this.active = false
    }
  }
}
</script>

<style lang="sass" scoped>
.file
  padding: 6px 6px 4px
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  gap: 3px
  position: relative
  &__delete
    border-radius: 4px
    position: absolute
    top: 0
    right: 0
    --size: 20px
    --svgsize: 16px
    opacity: 0
    pointer-events: none
    transform-origin: 50% 50%
    transform: scale(.7)
  &:hover &__delete
    opacity: 1
    pointer-events: auto
    transform: none
  &__icon svg
    width: 20px
    fill: white
    stroke: none
  &__name
    font-size: 11px
    color: white
    overflow: hidden
    text-overflow: ellipsis
    width: min-content
    max-width: 80px
    word-break: normal
.fileInput
  &__attach
    display: flex
    margin-left: auto
    order: 999999
    width: 20px
    margin-top: 5px
    margin-right: 5px

    svg
      --color1: transparent
      width: 20px
      height: 20px
      fill: colors.$green2
      stroke-width: 0.8
  &__files
    align-items: flex-start
    display: flex
    flex-wrap: wrap
    gap: 10px
    
  border-radius: 10px
  padding: 5px
  display: flex
  flex-direction: column
  align-items: stretch
  gap: 10px
  .file
      background: colors.$transi
      border-radius: 4px
  
  &_mini
    padding: 0 0 5px
    border-radius: 4px
    background: none
    .file
      background: colors.$calm
      border-radius: 4px
  &_mini &__attach
    margin-right: 0
    border-radius: 4px
    align-items: center
    justify-content: center
    width: 20px
    transition: .3s ease-out

    svg
      width: 14px
      transition: fill .3s ease-out
    &:hover
      background: colors.$green2
      svg
        fill: white



</style>