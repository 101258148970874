<template>
  <div class="square workspacesquare" @click="$store.dispatch('getWorkspace', { id: workspace.id })" @contextmenu.stop.prevent="menuShown = !menuShown">
    {{ workspace.name }}
    <PoplistMenu v-model="menuShown" @click.stop>
      <button class="hovered" @click="deleteWorkspace">Удалить воркспейс</button>
    </PoplistMenu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PoplistMenu from './PoplistMenu.vue';
export default {
  data: () => ({
    menuShown: false
  }),
  props: {
    workspace: { type: Object, required: true }
  },
  methods: {
    async deleteWorkspace() {
      if (!confirm("Удалить воркспейс " + this.workspace.name + '?'))
        return

      this.$store.commit('toggleLoad', 'deleteWorkspace')
      await fetch(this.url + 'workspace', {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ws: this.workspace.id })
      }).then(res => {
        if (!res.ok)
          throw (res.status)
        if (this.workspace.id == this.$store.state.workspace._id)
          this.$store.commit('setWorkspace', null)
        this.$store.dispatch('getWlist')
      })
        .catch(err => console.error(err))
      this.$store.commit('toggleLoad', 'deleteWorkspace')
    }
  },
  components: { PoplistMenu },
  computed: {
    ...mapState(['url'])
  }
}
</script>

<style lang="sass" scoped>
.workspacesquare
  font-size: 11px
  font-weight: 700
  text-align: center
  overflow-wrap: anywhere
  color: white
</style>