<template>
  <div class="comment" :class="{ 'active': editMode, 'highlighted': (copycomment?._id) && !readedcomments[copycomment._id] }">
    <div class="comment__body">
      <div class="comment__aboveline">
        <div class="comment__edited" v-if="copycomment?.edited">(изменено)</div>
        <div class="comment__date">{{ date }}</div>
      </div>
      <div class="comment__left" v-if="!access">
        <img class="comment__ava comment__ava_noaccess" :src="user.ava ? (url + user.ava) : require('@/assets/img/profile.svg')">
        <div class="comment__creator">{{ user.log }}</div>
      </div>
      <div class="comment__btns" v-if="access">
        <div class="comment__left">
          <img class="comment__ava" :src="user.ava ? (url + user.ava) : require('@/assets/img/profile.svg')">
          <div class="comment__creator">{{ user.log }}</div>
        </div>
        <Btn v-if="editMode" class="comment__btn comment__btn_done comment__btn_mini" @click="save">
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 5L8 15l-5-4" />
          </svg>
        </Btn>

        <Btn v-if="my && !createOnly" class="comment__btn comment__btn_edit comment__btn_mini" @click="editMode = !editMode">
          <svg v-if="!editMode" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
          </svg>
          <span v-if="editMode">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
          </span>
        </Btn>
        <Btn class="comment__btn comment__btn_del comment__btn_mini" v-if="access && !editMode" @click="deleteComment">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M3 6.38597C3 5.90152 3.34538 5.50879 3.77143 5.50879L6.43567 5.50832C6.96502 5.49306 7.43202 5.11033 7.61214 4.54412C7.61688 4.52923 7.62232 4.51087 7.64185 4.44424L7.75665 4.05256C7.8269 3.81241 7.8881 3.60318 7.97375 3.41617C8.31209 2.67736 8.93808 2.16432 9.66147 2.03297C9.84457 1.99972 10.0385 1.99986 10.2611 2.00002H13.7391C13.9617 1.99986 14.1556 1.99972 14.3387 2.03297C15.0621 2.16432 15.6881 2.67736 16.0264 3.41617C16.1121 3.60318 16.1733 3.81241 16.2435 4.05256L16.3583 4.44424C16.3778 4.51087 16.3833 4.52923 16.388 4.54412C16.5682 5.11033 17.1278 5.49353 17.6571 5.50879H20.2286C20.6546 5.50879 21 5.90152 21 6.38597C21 6.87043 20.6546 7.26316 20.2286 7.26316H3.77143C3.34538 7.26316 3 6.87043 3 6.38597Z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5956 22.0001H12.4044C15.1871 22.0001 16.5785 22.0001 17.4831 21.1142C18.3878 20.2283 18.4803 18.7751 18.6654 15.8686L18.9321 11.6807C19.0326 10.1037 19.0828 9.31524 18.6289 8.81558C18.1751 8.31592 17.4087 8.31592 15.876 8.31592H8.12404C6.59127 8.31592 5.82488 8.31592 5.37105 8.81558C4.91722 9.31524 4.96744 10.1037 5.06788 11.6807L5.33459 15.8686C5.5197 18.7751 5.61225 20.2283 6.51689 21.1142C7.42153 22.0001 8.81289 22.0001 11.5956 22.0001ZM10.2463 12.1886C10.2051 11.7548 9.83753 11.4382 9.42537 11.4816C9.01321 11.525 8.71251 11.9119 8.75372 12.3457L9.25372 17.6089C9.29494 18.0427 9.66247 18.3593 10.0746 18.3159C10.4868 18.2725 10.7875 17.8856 10.7463 17.4518L10.2463 12.1886ZM14.5746 11.4816C14.9868 11.525 15.2875 11.9119 15.2463 12.3457L14.7463 17.6089C14.7051 18.0427 14.3375 18.3593 13.9254 18.3159C13.5132 18.2725 13.2125 17.8856 13.2537 17.4518L13.7537 12.1886C13.7949 11.7548 14.1625 11.4382 14.5746 11.4816Z"></path>
            </g>
          </svg>
        </Btn>
      </div>
      <div class="comment__text" ref="taskText">
        <quill-editor ref="myQuillEditor" id="bla" v-if="!editMode" theme="snow" contentType="html" v-model:content="copycomment.text" placeholder="" :options="editorOptions" />
        <quill-editor ref="myQuillEditorEditable" v-else :toolbar="toolbar" :modules="editorModules" theme="snow" contentType="html" v-model:content="copycomment.text" :placeholder="createOnly ? 'Добавить комментарий' : 'Комментарий'" :options="editorOptions2"></quill-editor>
      </div>
      <div class="comment__files" v-if="(copycomment?.files?.length) || editMode">
        <FileInput :mini="true" :disabled="!editMode" v-model="copycomment.files" />
      </div>

    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Btn from './buttons/Btn.vue';
import { QuillEditor } from '@vueup/vue-quill'; // Import QuillEditor
import FileInput from './ui/FileInput.vue';
import MagicUrl from 'quill-magic-url';
import '@vueup/vue-quill/dist/vue-quill.snow.css'; // Import the styles you want


export default {
  data: () => ({
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link']
    ],
    editorOptions: {
      theme: 'snow',
      contentType: "html",
      readOnly: true,
      modules: {
        toolbar: false
      },
    },
    editorOptions2: { // Define your editor options here
      theme: 'snow',
      contentType: "html",
    },
    editorModules: [{
      name: 'MagicUrl',
      module: MagicUrl,
      options: {}
    }],
    editMode: false,
    copycomment: {
      text: '',
      files: []
    }
  }),
  props: {
    task: { type: Object, required: true },
    comment: {
      type: Object, default: () => ({
        text: '',
        files: []
      })
    },
    createOnly: { type: Boolean, default: false }
  },
  beforeMount() {
    if (this.createOnly)
      this.editMode = true
    this.copycomment = this.comment
    console.log(this.copycomment)
  },
  components: { Btn, QuillEditor, FileInput },
  methods: {
    async save() {
      let obj = { ws: this.$store.state.workspace._id, tk: this.task._id, text: this.copycomment.text, files: this.copycomment.files }
      let METHOD = 'POST'
      if (this.comment._id) {
        obj.cm = this.comment._id
        METHOD = 'PUT'
      }
      await fetch(this.url + 'comment', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: METHOD,
        body: JSON.stringify(obj)
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        if (!this.createOnly)
          this.editMode = false
        else {
          this.copycomment = { ...this.copycomment, text: '', files: [] };
          this.$refs.myQuillEditorEditable.setHTML('');
          this.readedcomments[await res.json()] = true
          this.$store.dispatch('updateSeen')
        }


        this.$store.dispatch('getComments')

      })
        .catch(err => console.error(err))
    },
    async deleteComment() {
      if (!confirm(`Удалить комментарий?`))
        return
      const tk = this.comment.task
      await fetch(this.url + 'comment', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
        body: JSON.stringify({ ws: this.$store.state.workspace._id, tk, cm: this.comment._id, })
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getComments')
      })
        .catch(err => console.error(err))
    }
  },
  computed: {
    ...mapState(['localRole', 'url', 'hash', 'readedcomments']),
    ...mapGetters(['userMap']),
    date(){
      let d = this.copycomment.date
      if (!d) return ''
      d = new Date(d)
      let hh = (d.getHours()+'').padStart(2,'0')
      let mins = (d.getMinutes()+'').padStart(2,'0')
      let dd = (d.getDate()+'').padStart(2,'0')
      let mm = (d.getMonth()+1+'').padStart(2,'0')
      let yy = ((d.getFullYear()%100)+'').padStart(2,'0')
      return `${hh}:${mins} ${dd}.${mm}.${yy}`
    },
    descr: {
      get() {
        return this.comment.text
      },
      //eslint-disable-next-line
      set() {
      }
    },
    user() {
      return this.userMap[this.comment.creator || this.$store.state.profile._id]
    },
    my() {
      return (this.comment.creator == this.$store.state.profile._id) || (this.createOnly)
    },
    access() {
      return (this.localRole == 'director') || this.my
    },
  },
  watch: {
    editMode(v, old) {
      if (v && (v != old)) {
        this.$nextTick(() => this.$el.querySelector('[contenteditable]')?.focus())
      }
    },
    comment: {
      deep: true,
      handler(v) {
        this.copycomment = v
      }
    }
  }
}
</script>

<style lang="sass">
.comment
  &:nth-child(even)
    background: rgba(0,0,0,.1)
  &:nth-child(odd)
    background: transparentize(colors.$transi, .25)
  &.active
    background: colors.$transi
    & ~ .comment.active
      display: none
  border-top: 1px solid lighten(transparentize(colors.$green2, .5), 30)
  .ql-toolbar
    display: none
  &__left
    margin-right: auto
    display: flex
    align-items: center
    gap: 10px
  &.highlighted &__aboveline
    color: colors.$gold
    filter: brightness(1.5)
    >*
      opacity: 1
    &::before
      margin-right: auto
      content: 'New!'
      width: 40px
      text-align: center
      font-weight: 700
      font-size: 10px
      color: colors.$gold
  &__ava
    width: 40px
    height: 40px
    border-radius: 4px
  &__body
    color: white
    padding: 4px 8px 8px
    
  &__aboveline
    min-height: 5px
    font-size: 11px
    display: flex
    justify-content: flex-end
    white-space: nowrap
    gap: 8px
    color: colors.$lighest
  &__date
    opacity: .8
  &__edited
    opacity: .6
    
  &__text
    border-radius: 4px
    margin: 4px 0
    font-size: 14px
    font-weight: 500
    min-height: 50px
    display: block
    .ql-container
      height: auto
    &:has(input:checked)
      .ql-editor
        max-height: initial
    .ql-editor
      padding: 0 !important
      &.ql-blank::before
        left: 0 !important

      max-height: 400px 
      overflow: hidden
      *, &::before, &::after
        color: white !important


  &__btns
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 10px    
  &__btn
    font-size: 16px
    border-radius: 4px
    width: 19px
    height: 19px
    
    &_edit
      display: flex
      align-items: center
      justify-content: center

      svg
        width: 14px
        fill: none
        stroke: colors.$green2
    &_mini
      padding: 2px
      width: fit-content
      &:hover svg 
        stroke: white !important
      svg
        width: 15px
        fill: none
        stroke: colors.$green2
</style>