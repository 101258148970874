<template>
  <div v-if="!editMode" class="taskwrapper">
    <div @click="compactMode = false; dragDisabled = false" class="task" @dblclick.stop="clearHash" :class="{ 'target': target, 'compactMode': compactMode, 'chequeMode': chequeMode }" v-bind="$attrs" :draggable="(!editMode && column?.access.includes(localRole) && !dragDisabled && draggable) ? 'true' : null" @pointerdrown="dragDisabled = false">
      <div class="task__body">
        <div class="task__title" @dblclick="copylink">{{ task.title }}
          <Transition name="fastfade">
            <div class="task__title-popup" v-if="copied">Скопировано!</div>
          </Transition>
        </div>
        <TagLine class="task__tagline" v-if="task.tags?.length" :tags="task.tags" :deletable="false" />
        <div class="dates">
          <label class="dates__input dates__input_hidden" v-if="task.date">
            <div class="dates__input-title">Поставлена</div>
            <input readonly type="date" :value="dateToValue(task.date)">
          </label>
          <label v-if="task.deadline" class="dates__input dates__input_hidden" :class="{ 'dates__input_red': (new Date() - new Date(task.deadline)) > 0, 'dates__input_done': task.done }">
            <div class="dates__input-title">{{ task.done ? "Выполнено" : "Выполнить до" }}</div>
            <input readonly type="date" :value="dateToValue(task.deadline)">
          </label>
        </div>
        <div class="task__price" v-if="task.stage != 'Поставлена'">
          <div class="task__price-text">Стоимость:</div>
          <div class="task__price-price" :class="{ 'performer': isPerformer }" @click.stop="changePrice">{{ task.price }} ₽</div>
        </div>
        <div class="task__stagebar" v-if="!task.done">
          <div class="task__stagebar-elem" v-for="stage in stages" :class="{ 'active': task.stage == stage, 'settable': settableStages.includes(stage) }" :key="stage" :data-stage="stage" @click="changeStage(stage)"></div>
        </div>
        <div class="task__stagebar" v-if="task.done && task.price && !task.paid">
          <div class="task__stagebar-elem needToBePaid">Не оплачено</div>
        </div>

        <div class="custom-select-picker">
          <ImagedThing class="task__ithing" v-if="!users?.length">
            <img :src="require('@/assets/img/profile.svg')">
            <span>Исполнитель не указан</span>
          </ImagedThing>
          <ImagedThing class="task__ithing" v-for="u in users" :key="u._id" v-else>
            <img :src="u.ava ? (url + u.ava) : require('@/assets/img/profile.svg')">
            <span>{{ u?.log }}</span>
          </ImagedThing>
        </div>
        <div class="task__text" ref="taskText" @pointerdown.stop="dragDisabled = true" @pointerleave="dragDisabled = false" @click.stop @pointerenter.stop="dragDisabled = true">
          <quill-editor theme="snow" contentType="html" v-model:content="descr" placeholder="" :options="editorOptions" />
          <label class="task__text-showmore">
            <input type="checkbox" @change="adjustHeight">
          </label>
        </div>
        <div class="task__files" v-if="task?.files.length">
          <FileInput :disabled="true" :modelValue="task.files" />
        </div>
        <div class="task__btns" v-if="access">
          <Btn v-if="!task.done" class="task__btn task__btn_done task__btn_mini taskcreator__btn" @click="doneTask">
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 5L8 15l-5-4" />
            </svg>
          </Btn>

          <Btn v-if="!task.done" class="task__btn taskcreator__btn" @click="editMode = !editMode">Редактировать</Btn>
          <Btn class="task__btn task__btn_del task__btn_mini taskcreator__btn" @click="deleteTask">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M3 6.38597C3 5.90152 3.34538 5.50879 3.77143 5.50879L6.43567 5.50832C6.96502 5.49306 7.43202 5.11033 7.61214 4.54412C7.61688 4.52923 7.62232 4.51087 7.64185 4.44424L7.75665 4.05256C7.8269 3.81241 7.8881 3.60318 7.97375 3.41617C8.31209 2.67736 8.93808 2.16432 9.66147 2.03297C9.84457 1.99972 10.0385 1.99986 10.2611 2.00002H13.7391C13.9617 1.99986 14.1556 1.99972 14.3387 2.03297C15.0621 2.16432 15.6881 2.67736 16.0264 3.41617C16.1121 3.60318 16.1733 3.81241 16.2435 4.05256L16.3583 4.44424C16.3778 4.51087 16.3833 4.52923 16.388 4.54412C16.5682 5.11033 17.1278 5.49353 17.6571 5.50879H20.2286C20.6546 5.50879 21 5.90152 21 6.38597C21 6.87043 20.6546 7.26316 20.2286 7.26316H3.77143C3.34538 7.26316 3 6.87043 3 6.38597Z"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5956 22.0001H12.4044C15.1871 22.0001 16.5785 22.0001 17.4831 21.1142C18.3878 20.2283 18.4803 18.7751 18.6654 15.8686L18.9321 11.6807C19.0326 10.1037 19.0828 9.31524 18.6289 8.81558C18.1751 8.31592 17.4087 8.31592 15.876 8.31592H8.12404C6.59127 8.31592 5.82488 8.31592 5.37105 8.81558C4.91722 9.31524 4.96744 10.1037 5.06788 11.6807L5.33459 15.8686C5.5197 18.7751 5.61225 20.2283 6.51689 21.1142C7.42153 22.0001 8.81289 22.0001 11.5956 22.0001ZM10.2463 12.1886C10.2051 11.7548 9.83753 11.4382 9.42537 11.4816C9.01321 11.525 8.71251 11.9119 8.75372 12.3457L9.25372 17.6089C9.29494 18.0427 9.66247 18.3593 10.0746 18.3159C10.4868 18.2725 10.7875 17.8856 10.7463 17.4518L10.2463 12.1886ZM14.5746 11.4816C14.9868 11.525 15.2875 11.9119 15.2463 12.3457L14.7463 17.6089C14.7051 18.0427 14.3375 18.3593 13.9254 18.3159C13.5132 18.2725 13.2125 17.8856 13.2537 17.4518L13.7537 12.1886C13.7949 11.7548 14.1625 11.4382 14.5746 11.4816Z"></path>
              </g>
            </svg>
          </Btn>
        </div>
      </div>
      <button class="task__comments-btn" :class="{ 'active': commentsShown, 'highlighted': notreaded }" @click="commentsShown = !commentsShown">
        <svg v-if="commentsShown" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M12.75 20C12.75 20.4142 12.4142 20.75 12 20.75C11.5858 20.75 11.25 20.4142 11.25 20L11.25 10.75H6.00002C5.69668 10.75 5.4232 10.5673 5.30711 10.287C5.19103 10.0068 5.25519 9.68417 5.46969 9.46967L11.4697 3.46967C11.6103 3.32902 11.8011 3.25 12 3.25C12.1989 3.25 12.3897 3.32902 12.5304 3.46967L18.5304 9.46967C18.7449 9.68417 18.809 10.0068 18.6929 10.287C18.5768 10.5673 18.3034 10.75 18 10.75H12.75L12.75 20Z"></path>
          </g>
        </svg>
        <svg v-else version="1.0" id="Layer_1" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve" fill="var(--color1)">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <path fill-rule="evenodd" clip-rule="evenodd" fill="var(--color2)" d="M60,0H4C1.789,0,0,1.789,0,4v40c0,2.211,1.789,4,4,4h8v12 c0,1.617,0.973,3.078,2.469,3.695C14.965,63.902,15.484,64,16,64c1.039,0,2.062-0.406,2.828-1.172L33.656,48H60 c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z M56,40H32c-1.023,0-2.047,0.391-2.828,1.172L20,50.344V44c0-2.211-1.789-4-4-4H8V8 h48V40z"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" fill="var(--color3)" d="M56,40H32c-1.023,0-2.047,0.391-2.828,1.172L20,50.344V44 c0-2.211-1.789-4-4-4H8V8h48V40z"></path>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="task__comments" :class="{ 'active': commentsShown }">
      <div class="task__comments-content" @click="seen">
        <Comment :task="task" :createOnly="false" v-for="comment in commentMap[task._id]" :comment="comment" :key="comment._id" />
        <Comment :task="task" :createOnly="true" />
      </div>
    </div>
  </div>
  <TaskCreator :class="{ 'target': target }" :column="column" :task="task" style="order:0" @back="back" v-if="editMode && column" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Comment from './Comment.vue';
import ImagedThing from './ui/ImagedThing.vue';
import TagLine from './ui/TagLine.vue';
import Btn from './buttons/Btn.vue';
import { QuillEditor } from '@vueup/vue-quill'; // Import QuillEditor
import TaskCreator from './TaskCreator.vue';
import FileInput from './ui/FileInput.vue';


export default {
  mounted() {
    this.observeTaskTextChanges();
    this.adjustHeight()
    this.ccount++
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  data: () => ({
    stages: ['Поставлена', 'Выполняется', 'На проверке'],
    commentsShown: false,
    editMode: false,
    compactMode: false,
    dragDisabled: false,
    editorOptions: {
      theme: 'snow',
      contentType: "html",
      readOnly: true,
      modules: {
        toolbar: false
      },
    },
    copied: false
  }),
  props: {
    compact: { type: Boolean, default: false },
    task: { type: Object },
    column: { type: Object },
    draggable: { type: Boolean, default: true },
    chequeMode: { type: Boolean, default: false },
  },
  components: { ImagedThing, TagLine, Btn, QuillEditor, TaskCreator, FileInput, Comment },
  beforeMount() {
    this.compactMode = this.compact
  },
  methods: {
    seen() {
      for (let c of this.commentMap[this.task._id])
        this.readedcomments[c._id] = true
      this.$store.dispatch('updateSeen')
    },
    dateToValue(date) {
      let d = new Date(date)
      return d.getFullYear() + '-' + ((d.getMonth() + 1 + '').padStart(2, '0')) + '-' + ((d.getDate() + '').padStart(2, '0'))
    },
    clearHash() {
      if (this.hash && (this.hash == ('#' + this.task._id))) {
        window.location.hash = ''
        this.$store.commit('setHash', null)
      }
    },
    copylink() {
      if (this.chequeMode)
        return
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 2000)
      const textarea = document.createElement('textarea')
      textarea.value = window.location.origin + this.$route.path + '#' + encodeURIComponent(this.task._id)
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
    },
    back() {
      this.editMode = false
      window.requestAnimationFrame(() => {
        this.$nextTick(() => {
          this.adjustHeight()
          setTimeout(this.adjustHeight, 5)
          setTimeout(this.adjustHeight, 20)
          setTimeout(this.adjustHeight, 40)
          setTimeout(this.adjustHeight, 60)
          setTimeout(this.adjustHeight, 80)
        })
      })
    },
    adjustHeight() {
      const taskText = this.$refs.taskText;
      if (taskText) {
        taskText.style.height = 'auto';
        taskText.style.height = taskText.scrollHeight + 'px';
      }
    },
    observeTaskTextChanges() {
      const taskText = this.$refs.taskText;
      if (taskText) {
        this.observer = new MutationObserver(() => {
          this.adjustHeight()
        })
        this.observer.observe(taskText, {
          childList: true, // observe direct children changes
          subtree: true, // observe all descendants
          characterData: true, // observe text changes
        });
        // Initial adjustment if needed
        this.adjustHeight();
      }
    },
    async changePrice(){
      if (!this.isPerformer || !this.column)
        return
      let col = this.column._id
      let obj = { ws: this.$store.state.workspace._id, col }
      obj.price = prompt('Укажите новую стоимость:')
      obj.tk = this.task._id
      obj.updateAction = 'setPrice'
      await fetch(this.url + 'task', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(obj)
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getTasks')
      })
        .catch(err => {console.error(err); alert(err); })
    },
    async changeStage(stage) {
      if (!this.column)
        return
      if (!this.settableStages.includes(stage))
        return
      if (!confirm(`Перевести задание "${this.task.title || "(без названия)"} на стадию ${stage}?`))
        return
      let col = this.column._id
      let obj = { ws: this.$store.state.workspace._id, col }
      if (this.stages.indexOf(stage) == -1) return alert("Ошибка: неверный stage index")
      if (this.stages.indexOf(this.task.stage) == -1) return alert("Ошибка: неверный task.stage index")
      let delta = this.stages.indexOf(stage) - this.stages.indexOf(this.task.stage)
      switch (delta) {
        case 1:
          obj.updateAction = 'nextStage'
          break
        case -1:
          obj.updateAction = 'downStage'
          break
        default:
          return alert(`Ошибка: неверная delta (${delta})`)
      }
      obj.tk = this.task._id

      await fetch(this.url + 'task', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(obj)
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getTasks')
      })
        .catch(err => {console.error(err); alert(err); })
    },
    async doneTask() {
      if (!this.column)
        return
      if (!confirm(`Пометить задание "${this.task.title || "(без названия)"} как выполненное?`))
        return

      let col = this.column._id
      let obj = { ws: this.$store.state.workspace._id, col }
      obj.tk = this.task._id
      obj.done = true
      obj.deadline = new Date()
      await fetch(this.url + 'task', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(obj)
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getTasks')
      })
        .catch(err => console.error(err))
    },
    async deleteTask() {
      if (!confirm(`Удалить задание "${this.task.title || "(без названия)"}?"`))
        return
      const tk = this.task._id
      await fetch(this.url + 'task', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
        body: JSON.stringify({ ws: this.$store.state.workspace._id, tk })
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getTasks')
      })
        .catch(err => { console.error(err); alert(err); })
    }
  },
  computed: {
    ...mapState(['localRole', 'url', 'hash', 'readedcomments', 'profile']),
    ...mapGetters(['userMap', 'commentMap']),
    notreaded() {
      try {
        for (let c of this.commentMap[this.task._id]) {
          if (!this.readedcomments[c._id])
            return true
        }
      } catch (err) {
        return false
      }
      return false
    },
    access() {
      return (this.localRole == 'director') || ((this.localRole == 'manager') && (this.task.createdByYou))
    },
    target() {
      return this.hash == ('#' + this.task._id)
    },
    descr: {
      get() {
        return this.task.description
      },
      //eslint-disable-next-line
      set() {
      }
    },
    users() {
      let res = []
      for (let id of this.task.performers) {
        if (this.userMap[id])
          res.push(this.userMap[id])
      }
      return res
    },
    isPerformer() {
      return this.users?.find(el => el._id == this.profile._id)
    },
    settableStages() {
      let settable = []
      let index = this.stages.indexOf(this.task.stage)
      if (index == -1) {
        console.error('Ошибка: неверный stage index')
        return []
      }
      if (this.isPerformer) {
        let next = this.stages[index + 1]
        if (next)
          settable.push(next)
      }
      if (this.access && (index == (this.stages.length - 1))) {
        let prev = this.stages[index - 1]
        if (prev)
          settable.push(prev)
      }
      return settable
    }
  }
}
</script>

<style lang="sass">
.taskwrapper:has(.taskcreator.target),.taskwrapper:has(.task.target)
  order: -100
.task
  background: colors.$transi
  margin: 0px calc(var(--colpad) * (-1))
  width: calc(100% + 2 * var(--colpad))
  padding: var(--colpad)
  border-radius: 10px
  position: relative
  &.target
    order: -100
    background: lighten(colors.$transi, 40)
    //border: 2px solid white
    //padding: calc(var(--colpad) - 2px)
  &__price
    display: flex
    justify-content: space-between
    background: colors.$transi
    padding: 5px 10px
    border-radius: 10px
    &-price
      &.performer
        cursor: pointer
      font-weight: 500
      color: colors.$gold
  &__stagebar
    display: flex
    border-radius: 10px
    overflow: hidden
    &-elem.settable
      cursor: pointer
    &-elem
      font-size: 14px
      font-weight: 700
      flex: 0 0 0
      text-align: center
      &.needToBePaid
        background: transparentize(colors.$gold, .3) !important
        width: 100% !important
        flex: initial !important
        padding: 5px 20px
      &.settable
        flex: 1 1 0
      &:nth-child(1)
        background: mix(colors.$transparent, colors.$lightgreen, 80%)
        &.active
          flex: 1 1 0
      &:nth-child(2)
        background: mix(colors.$transparent, colors.$lightgreen, 60%)
        &.active
          flex: 2 1 0
      &:nth-child(3)
        background: mix(colors.$transparent, colors.$lightgreen, 40%)
        &.active
          flex: 3 1 0
      &.active ~ *
        padding: 5px 15px
        flex: 1 1 0
        filter: grayscale(1)
      &.active
        flex: 1 1 0
        padding: 5px 15px
        &::after
          content: attr(data-stage)
  &__comments
    background: colors.$transi
    border-radius: 0 0 10px 10px
    display: grid
    grid-template-rows: 0fr
    transition: grid-template-rows .3s ease-out
    &.active
      grid-template-rows: 1fr
    &-content
      border-radius: 10px
      &::before
        content: ''
        width: 100%
        height: 40px
        display: block
      overflow: hidden
    &-btn
      &.highlighted
        background: colors.$gold
      &.active
        width: 36px
        height: 30px
        display: flex
        justify-content: center
        align-items: center
        text-align: center

        background: rgba(255,255,255,0)
        &::after, &::before
          display: none
        svg
          fill: colors.$green2
          filter: drop-shadow(0px 0px 2px transparentize(colors.$lightgreen, .5))
          width: 24px
        &.highlighted svg
          fill: colors.$gold
      position: absolute
      top: 100%
      padding: 0px 3px
      border-radius: 0 0 10px 10px
      background: colors.$transi
      display: block
      right: 15px
      transition: .2s ease-out
      &::before, &::after
        z-index: 1
        background: rgba(255,255,255,.8)
        height: 2px
        position: absolute
        width: 16px
        left: 50%
        transform: translate(-50%, -50%)
        top: 30%
        content: ''
      &::after
        top: 43%
        width: 12px
        left: calc(50% - 2px)

      svg
        filter: drop-shadow(1px  0px 0px colors.$darkgreen) drop-shadow(-1px  0px 0px colors.$darkgreen) drop-shadow(0px  1px 0px colors.$darkgreen) drop-shadow( 0px -1px 0px colors.$darkgreen)
        width: 30px
        --color1: transparent
        --color2: transparent
        --color3: #{colors.$green2}
  &__ithing
    pointer-events: none !important
  &__body
    display: grid
    grid-template-columns: 1fr
    gap: 10px
  &__title
    color: white
    font-size: 18px
    font-weight: 700
    padding: 8px 12px
    text-align: center
    background: none 
    &-popup
      background: colors.$green2
      z-index: 100
      position: absolute
      left: 50%
      transform: translate(-50%, 50%)
      bottom: 100%
      font-size: 16px
      padding: 6px 10px
      border-radius: 10px
  &__text
    position: relative
    color: white
    border-radius: 10px
    font-size: 14px
    padding: 8px
    font-weight: 500
    background: transparentize(colors.$darkgreen, .45)
    min-height: 100px
    display: block
    container-type: size
    container-name: container
    .ql-container
      height: auto
    &:has(input:checked)
      .ql-editor
        max-height: initial
    .ql-editor
      max-height: 400px 
      overflow: hidden
    &-showmore
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 60px
      cursor: pointer
      background: linear-gradient(to bottom, transparentize(colors.$darkgreen, 1) 0%, transparentize(colors.$darkgreen, .3) 50%,colors.$darkgreen 80%, colors.$darkgreen 100%)
      &:hover
        background: linear-gradient(to bottom, transparentize(lighten(colors.$darkgreen, 2), 1) 0%, transparentize(lighten(colors.$darkgreen, 2), .3) 50%,lighten(colors.$darkgreen, 2) 80%, lighten(colors.$darkgreen, 2) 100%)
      z-index: 50
      text-align: center
      align-items: flex-end
      justify-content: center
      padding-bottom: 10px
      border-radius: 10px
      input
        opacity: 0
        pointer-events: none
        position: absolute
      &:has(input:not(:checked))
        &::after
          content: 'Показать всё'
      &:has(input:checked)
        height: 40px
        position: relative
        background: none
        display: flex !important
        &::after
          content: 'Скрыть'
      display: none
      @container container (min-height: 384px)
        display: flex !important
  &.compactMode &__text, &.compactMode &__btns, &.compactMode .custom-select-picker, .compactMode &__price
    display: none
  &.compactMode &__stagebar
    margin-top: 5px
    margin-bottom: 5px
  &.chequeMode
    .task__text, .task__files, .task__comments, .task__comments-btn
      display: none !important
    .task__body
      padding: 10px 
  &.compactMode
    cursor: pointer
    padding-top: 5px
    padding-bottom: 5px
    .task__body
      gap: 0
    .task__title
      padding: 5px
    .dates__input-title, .task__comments-btn
      display: none
    
  &__btns
    display: flex
    align-items: center
    gap: 10px    
  &__btn
    font-size: 16px
    &_mini
      padding: 8px
      width: fit-content
      margin-left: auto
      &:hover svg 
        stroke: white !important
      svg
        width: 20px
        fill: none
        stroke: colors.$green2
</style>