<template>
  <div class="profilesquare square" @click.stop.prevent="menuShown = !menuShown" @contextmenu.stop.prevent="menuShown = !menuShown">
    <img class="profilesquare__img" v-if="profile?.ava" :src="url + profile.ava + '?u=' + update" :key="update"/>
    <svg v-else viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <circle cx="12" cy="6" r="4"></circle>
        <ellipse cx="12" cy="17" rx="7" ry="4"></ellipse>
      </g>
    </svg>
    <PoplistMenu v-model="menuShown" @click.stop>
      <button @click="setAva">Установить аватарку</button>
      <button class="hovered" @click="logout">Выйти</button>
    </PoplistMenu>
  </div>
</template>

<script>
import PoplistMenu from './PoplistMenu.vue';
import { mapState } from 'vuex';
export default {
  data: () => ({
    menuShown: false,
    update: 0
  }),
  components: { PoplistMenu },
  computed: {
    ...mapState(['profile','url'])
  },
  methods: {
    async setAva() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg, image/webp';
      fileInput.click();
      fileInput.addEventListener('change', ()=> {
        if (fileInput.files.length > 0) {
          const file = fileInput.files[0]
          const formData = new FormData()
          formData.append('ava', file) 
          let state = this.$store.state
          let commit = this.$store.commit

          commit('toggleLoad', 'addAva')
          fetch(state.url + 'ava', {
            method: 'POST',
            body: formData,
            credentials: 'include',
          })
            .then(async res=> {
              if (!res.ok)
                throw await res.text()
              this.$store.dispatch('getProfile')
            })
            .catch(err=>console.error(err))
          commit('toggleLoad', 'addAva')
        }
      });
    },
    async logout(){
      this.$store.commit('toggleLoad', 'logoutBtn')
      await fetch(this.url + 'logout', {
        credentials: 'include',
      })
        .then(async res=>{
          if (!res.ok) throw (await res.text())
          this.$store.dispatch('reset')
          this.$store.dispatch('checkAuth')
        }).catch(err=>{console.error(err); alert(err)})
      this.$store.commit('toggleLoad', 'logoutBtn')
    }
  },
  watch: {
    profile(){
      this.update++
    } 
  }
}
</script>

<style lang="sass">
.square
  position: relative
  z-index: 10
  width: 50px
  height: 50px
  border-radius: 10px
  background: colors.$transi
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  transition: background .1s ease-out
  box-shadow: 0px 3px 5px rgba(0,0,0,.3)
  &:has(img)
    background: none !important
  &:hover
    background: colors.$darkgreen2
.profilesquare
  &__img
    border-radius: 10px
    position: absolute
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center
  svg
    width: 35px
    fill: colors.$lightgreen
</style>