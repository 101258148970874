<template>
  <div class="addusers" @click="activate">
    Добавить участников
    <PoplistMenu class="addusers__popmenu" v-model="menuShown" @click.stop>
      <label class="addusers__item" v-for="u in notme" :key="u._id">
        <input class="addusers__item-check" type="checkbox" v-model="users[u._id]">
        <img class="addusers__item-ava" :src="u.ava ? (url + u.ava) : require('@/assets/img/profile.svg')">
        <div class="addusers__item-name">{{ u.log }}</div>
      </label>
      <button v-if="notme?.length" @click="addUsers">Добавить</button>
      <div v-else>Сейчас добавлять некого</div>
    </PoplistMenu>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PoplistMenu from './PoplistMenu.vue';
export default {
  data: () => ({
    users: {},
    notme: [],
    menuShown: false
  }),
  methods: {
    addUsers() {
      let ids = []
      for (let i in this.users)
        if (this.users[i])
          ids.push(i)

      fetch(this.url + 'addToWorkspace', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ws: this.workspace._id, users: ids })
      })
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          this.$store.dispatch('getWorkspace', { id: this.workspace._id, silent: true })
          this.activate()
        })
        .catch(err => console.error(err))

    },
    activate() {
      fetch(this.url + 'users', {
        credentials: 'include',
      })
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          return res.json()
        })
        .then(json => {
          let m = this.workspace.userlist.map(el => el._id)
          this.notme = json.filter(el => !m.includes(el._id))
          this.menuShown = !this.menuShown
        })
        .catch(err => console.error(err))
    },

  },
  components: { PoplistMenu },
  computed: {
    ...mapState(['url', 'workspace', 'profile']),
  },
  watch: {
    workspace: {
      handler(v) {
        let res = {}
        for (let u in v.userlist) {
          if (u._id != this.profile._id)
            res[u._id] = false
        }
        this.users = res
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.addusers
  position: relative
  border-radius: 10px
  text-align: center
  cursor: pointer
  padding: 10px 15px 
  font-size: 16px
  font-weight: 500
  color: white
  background: colors.$darkdark
  transition: background .1s ease-out
  box-shadow: 0px 3px 5px rgba(0,0,0,.3)
  &:hover
    background: colors.$darkgreen2
  & &__item
    display: flex
    align-items: center
    position: relative
    padding: 10px
    gap: 10px
    cursor: pointer    
    &:hover, &:focus-visible, &:has(input:checked)
      background: colors.$darkgreen2
    &-check
      position: absolute
      pointer-events: none
      opacity: 0
    &-ava
      width: 40px
      height: 40px
      border-radius: 10px
      overflow: hidden
      object-fit: cover
      object-position: center
    &-name
      font-size: 14px
      font-weight: 500
  & &__popmenu
    left: 0
    top: calc(100% + 10px)
    min-width: 100%

      
</style>