<template>
  <div class="taskcreator">
    <div class="taskcreator__container" :class="{ 'is-expanded': isExpanded }">
      <div class="taskcreator__container-body">
        <textarea class="taskcreator__input" :class="{ 'taskcreator__input_active': title }" v-model="title" placeholder="Заголовок" @input="adjustTextareaHeight($event)"></textarea>
        <TagInput class="taskcreator__taginput" v-model="tags" />
        <div class="dates">
          <label class="dates__input">
            <div class="dates__input-title">Поставлена</div>
            <input type="date" v-model="date">
          </label>
          <label class="dates__input" :class="{ 'dates__input_red': (new Date() - new Date(deadline)) > 0 }">
            <div class="dates__input-title">Выполнить до</div>
            <input type="date" v-model="deadline">
          </label>
        </div>
        <SelectMultiple :options="workspace_userlist" v-model="performers" :class="{ 'taskcreator__select_active': performers?.length }" tooltext="Выберите исполнителя" />
        <div class="taskcreator__text" :class="{ 'taskcreator__text_active': description }">
          <quill-editor :toolbar="toolbar" :modules="editorModules" theme="snow" contentType="html" v-model:content="description" placeholder="Описание задачи" :options="editorOptions"></quill-editor>
        </div>
        <FileInput v-model=files />
      </div>
    </div>
    <Btn class="taskcreator__btn" @click="toggleContainer">{{ btnText }}</Btn>
    <Btn v-if="task" class="taskcreator__btn taskcreator__btn_space" @click="$emit('back')">Отмена </Btn>
  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';
import SelectMultiple from './ui/SelectMultiple.vue';
import TagInput from './ui/TagInput.vue';
import { QuillEditor } from '@vueup/vue-quill'; // Import QuillEditor
//import '@vueup/vue-quill/dist/vue-quill.bubble.css'; // Import the styles you want
import '@vueup/vue-quill/dist/vue-quill.snow.css'; // Import the styles you want
import { mapState, mapGetters } from 'vuex';
import MagicUrl from 'quill-magic-url';
import FileInput from './ui/FileInput.vue';

export default {
  data: () => ({
    toolbar: [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link']
    ],
    performers: [],
    title: '',
    description: '',
    tags: [],
    files: [],
    date: '2024-01-01',
    deadline: '2025-01-01',
    isExpanded: false,
    editorModules: [{
      name: 'MagicUrl',
      module: MagicUrl,
      options: {}
    }],
    editorOptions: { // Define your editor options here
      theme: 'snow',
      contentType: "html",
    }
  }),
  components: { Btn, TagInput, QuillEditor, SelectMultiple, FileInput },
  props: {
    column: { type: Object },
    task: { type: Object }
  },
  beforeMount() {
    this.date = this.dateToValue(new Date().getTime())
    this.deadline = this.dateToValue(new Date().getTime() + 1000 * 60 * 60 * 24 * 28)
    if (this.task) {
      this.isExpanded = true
      for (let el of ['performers', 'title', 'description', 'tags', 'date', 'deadline','files']) {
        if (['date', 'deadline'].includes(el)) {
          if (this.task[el])
            this[el] = this.dateToValue(this.task[el])
        }
        else
          this[el] = this.task[el]
      }
    }
  },
  computed: {
    ...mapState(['url']),
    ...mapGetters(['workspace_userlist']),
    btnText() {
      if (this.isExpanded) {
        if (this.task)
          return "Сохранить"
        return "Создать задание"
      }
      else
        return "Добавить задание"
    }
  },
  methods: {
    dateToValue(date){
      let d = new Date(date)
      return d.getFullYear() + '-' + ((d.getMonth() + 1 + '').padStart(2,'0')) + '-' + ((d.getDate() + '').padStart(2,'0'))
    },
    adjustTextareaHeight(event) {
      const element = event.target
      element.style.height = '0px'
      element.style.height = (element.scrollHeight) + 'px'
    },
    toggleContainer() {
      if (this.isExpanded) {
        this.send()
      } else
        this.isExpanded = !this.isExpanded
    },
    async send() {
      let col = this.column._id
      let { title, performers, tags, description, date, deadline, files } = this
      let obj = { ws: this.$store.state.workspace._id, col, performers, tags, title, description, date, deadline, files }
      let METHOD = 'POST'
      if (this.task) {
        obj.tk = this.task._id
        METHOD = 'PUT'
      }
      await fetch(this.url + 'task', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: METHOD,
        body: JSON.stringify(obj)
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.$store.dispatch('getTasks')
        this.clear()
        if (this.task)
          this.$emit('back')
      })
        .catch(err => console.error(err))

    },
    clear() {
      this.performers = []
      this.title = ''
      this.description = ''
      this.tags = []
      this.isExpanded = false
    },
  }
}
</script>


<style lang="sass">
.dates
  display: flex
  justify-content: space-between
  &__input
    font-size: 16px
    font-weight: 500

    &_done &-title, &_done input
      color: colors.$lightgreen !important
      text-align: center
    &_red:not(&_done) &-title
      font-weight: 700
      color: red 
    &-title
      padding: 0 3px
    input
      font-size: 15px
      font-weight: 500
      color: white
      display: block
      background: colors.$transi
      border-radius: 10px
      padding: 5px 10px
      width: 128px
      display: block
      margin-top: 2px
      &::-webkit-calendar-picker-indicator 
        filter: invert(1)
    &_red:not(&_done)
      input
        color: red
        font-weight: 700
    &_hidden:nth-child(n):nth-last-child(n)
      display: flex 
      flex-direction: column
      flex: 1 1 0
      max-width: 48%
      input
        align-self: stretch
        width: auto
        text-align: center
      input
        appearance: none
        min-width: 105px
        @-moz-document url-prefix() 
          font-size: 13.5px
          font-weight: 700
          min-width: 110px
          line-height: 20px
        &::-webkit-inner-spin-button,&::-webkit-calendar-picker-indicator
          display: none
          -webkit-appearance: none
          appearance: none

.taskcreator
  order: 999999
  margin: 0 calc(var(--colpad) * (-1))
  width: calc(100% + 2 * var(--colpad))
  padding: var(--colpad)
  background: transparentize(colors.$darkgreen, .45)
  border-radius: 10px
  &__container
    display: grid
    grid-template-rows: 0fr // Container is collapsed by default
    transition: 250ms grid-template-rows ease, 250ms margin ease
      
    &.is-expanded
      margin-bottom: 10px
      grid-template-rows: 1fr // Container expands to fit content

    &-body
      overflow: hidden
      width: 100%
      display: grid
      grid-template-columns: 100%
      gap: 10px
    &.is-expanded &-body
      width: calc(100% + 200px)
      margin: 0 -100px -100px
      padding: 0 100px 100px
  &__btn
    width: 100%
    &_space
      margin-top: 16px
  &__input, &__text
    background: colors.$transi
    color: white
    border-radius: 10px

  &__text
    position: relative
    z-index: 1
    font-size: 14px
    padding: 0px
    min-height: 140px
    height: fit-content
    font-weight: 500
    word-break: break-word
    
  & &__input
    height: 38px
    font-size: 18px
    font-weight: 700
    padding: 8px 12px
    word-break: break-word
    transition: none !important
    background: colors.$transi 
    resize: none
    overflow: hidden
    &::placeholder
      color: colors.$dim
      
    &_active
      text-align: center
      background: none
</style>
