<template>
  <Transition name="fastfade">
    <div class="popmenu" v-if="modelValue" v-click-outside="()=>$emit('update:modelValue', false)" @contextmenu.stop>
      <ul class="popmenu__list">
        <slot />
      </ul>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    modelValue: { type: Boolean, required: true }
  }
}
</script>

<style lang="sass">
.popmenu
  position: absolute
  z-index: 10
  top: 0
  left: calc(100% + 10px)
  padding: 6px
  border-radius: 10px
  background: colors.$darkgreen
  min-width: 150px
  &__list
    display: grid
    grid-template-columns: 1fr
    gap: 6px
    >*
      line-height: 1.286
      width: 100%
      background: colors.$darkgreen
      color: white
      cursor: pointer
      white-space: nowrap
      border-radius: 10px
      transition: background .1s ease-out 
      padding: 10px
      font-size: 14px
      font-weight: 500
      &:hover
        background: colors.$green2
</style>