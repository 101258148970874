<template>
  <button class="btn" :class="{'btn_small':small, ['btn_' +color]:color}">
    <slot />
  </button>
</template>

<script>
  export default {
    props: {
      small: Boolean,
      color: String
    }
  }
</script>

<style lang="sass">
.btn
  position: relative
  --background: #{colors.$transi}
  --hover: #{colors.$green2}
  font-weight: 600
  padding: 8px 15px
  background: var(--background)
  border: none
  cursor: pointer
  display: block
  outline: none
  text-align: center
  color: white
  border-radius: 10px
  font-size: 20px
  align-items: center
  justify-content: center
  text-align: center
  text-shadow: -1px -1px 1px rgba(255,255,255,.5), 1px 1px 1px rgba(0,0,0,.5)
  svg
    display: block
    width: 12px
    stroke: white
    fill: white
  transition: background .3s ease-out
  &:hover, &:focus-visible
    background: var(--hover)

  &[disabled]
    --background: rgba(150,150,150,1) !important
    pointer-events: none !important
  &_small
    font-size: 14px
    padding: 5px 10px
  &_dark
    --background: #{colors.$darkdark}
    --hover: #{colors.$darkgreen2}
  &__loader
    position: absolute
    top:50%
    left: 50%
    transform: translate(-50%,-50%)
  &[disabled] &__loader
    color: colors.$lightgreen
  &[disabled]:has(.btn__loader)
    --background: #{colors.$transi} !important
</style>