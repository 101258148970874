<template>
  <div class="loader" :style="{'--size':size, '--slim':slim}">
    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g data-idx="1">
        <circle cx="50" cy="50" r="32" stroke-width="8" stroke="currentColor" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round" data-idx="2" transform="matrix(0.998022773339599,-0.06285335230149164,0.06285335230149164,0.998022773339599,-3.0438062820545255,3.2415289480946328)">
        </circle>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {type: String, default: '40px'},
    slim: {type: Number, default: 0}
  }
}
</script>

<style lang="sass">
@keyframes rotating 
  0% 
    transform: rotate(0deg)
  100% 
    transform: rotate(360deg)

.loader
  width: var(--size)
  height: var(--size)
  margin-top: calc(var(--slim) * var(--size) * (-0.25))
  margin-bottom: calc(var(--slim) * var(--size) * (-0.25))
  svg
    transform-origin: center center
    animation: rotating 1s linear infinite
    width: 100%
    height: 100%
  
</style>