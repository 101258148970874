<template>
  <div class="tag-input-container">
    <div class="input-group">
      <input type="text" v-model="currentTag" @keyup.enter="addTag" placeholder="Add a tag" class="tag-input" :class="{ 'filled': modelValue.length }" />
      <button @click="addTag" class="add-tag-btn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" xmlns:v="https://vecta.io/nano">
          <path d="M201.5 73.5l.2 19.9-1.6 3.3c-2.9 5.9-8.1 9.6-16.4 11.8-3.2.8-6.7.9-39.4 1l-35.9.2V84.4 59.1L65.3 90.9 16 127.2c-3.3 2.5-6.1 4.7-6 4.9.1.3 22.2 16.2 49.2 35.4l49 34.9.1-24.7.1-24.7h43.2l48.4-.7c15.9-2.1 26.4-6.7 34.8-15 5.1-5.1 7.8-9.8 9.8-17 1.1-3.9 1.1-4.9 1.3-35.4l.2-31.4h-22.4-22.3l.1 20z" />
        </svg></button>
    </div>
    <TagLine :tags="tags" class="tags-container" @removeTag="removeTag($event)" />
  </div>
</template>

<script>
import TagLine from './TagLine.vue';

export default {
  props: ['modelValue'],
  data() {
    return {
      currentTag: '',
      tags: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.tags = newValue;
      },
      immediate: true,
    },
    tags(newValue) {
      this.$emit('update:modelValue', newValue);
    },
  },
  methods: {
    addTag() {
      if (this.currentTag.trim() !== '') {
        this.tags.push(this.currentTag.trim());
        this.currentTag = '';
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
  },
  components: { TagLine }
}
</script>

<style lang="sass" scoped>
.tag-input-container
  display: flex
  flex-direction: column
  

.input-group
  position: relative
.tag-input
  width: 100%
  padding-right: 20px
  border-radius: 10px
  padding: 8px 12px
  background: colors.$transi
  transition: bakground .3s ease-out
  color: white
  
  &:focus-visible:not(.filled)
    background: colors.$darkgreen2

  &.filled:not(:focus)
    background: colors.$transi
  
.tags-container
  margin-top: 10px

.add-tag-btn
  cursor: pointer
  position: absolute
  right: 5px
  top: 50%
  transform: translateY(-50%)
  padding: 5px
  border-radius: 5px
  background: colors.$lighest
  svg
    width: 10px

</style>
