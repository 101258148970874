<template>
  <div class="custom-select" :class="{'active':dropdownVisible}" :disabled="disabled?'disabled':null">
    <select v-model="selectedValue" @change="updateValue($event.target.value)" class="hidden-select" tabindex="-1">
      <option v-for="(option, index) in options" :key="index" :value="option._id">
        {{ option.log }}
      </option>
    </select>
    <ImagedThing class="selected-option" :filled="selectedValue? true : false" tabindex="0" @click="toggleDropdown" @keydown.space.prevent="toggleDropdown" @keydown.enter.prevent="toggleDropdown">
      <img :src="selectedOption.ava? (url + selectedOption.ava) : require('@/assets/img/profile.svg')" :alt="selectedOption?.log" v-if="selectedOption">
      <span :class="{'dimtext': !(selectedOption?.log)}">{{ selectedOption?.log || tooltext }}</span>
    </ImagedThing>
    <div class="options-dropdown" v-show="dropdownVisible">
      <ImagedThing v-for="(option, index) in options" :key="index" class="option-preview" tabindex="0" @click="selectOption(option._id)" @keydown.space.prevent="selectOption(option._id)" @keydown.enter.prevent="selectOption(option._id)">
        <img :src="option.ava? (url + option.ava) : require('@/assets/img/profile.svg')" :alt="option.log" class="option-image">
        <span>{{ option.log }}</span>
      </ImagedThing>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImagedThing from './ImagedThing.vue';

export default {
  name: 'CustomSelect',
  props: {
    disabled: {
      type: Boolean, default: false
    },
    tooltext: {
      type: String, default: 'Select an option'
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: false,
    }
  },
  data() {
    return {
      selectedValue: this.modelValue,
      dropdownVisible: false,
    };
  },
  computed: {
    ...mapState(['url']),
    selectedOption() {
      return this.options.find(option => option._id === this.selectedValue);
    }
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectOption(id) {
      this.selectedValue = id;
      this.updateValue(id);
      this.dropdownVisible = false;
    },
  },
  components: { ImagedThing }
}
</script>

<style lang="sass">

.custom-select
  &[disabled]
    pointer-events: none !important
  position: relative
  z-index: 2
  border-radius: 10px
  &.active .selected-option
    border-radius: 10px 10px 0 0
  
  .hidden-select
    position: absolute
    pointer-events: none
    opacity: 0
    height: 0
    width: 0
  .selected-option
    cursor: pointer

  .option-preview
    background: colors.$darkgreen
    &:hover, &:focus-visible
      background: colors.$darkgreen2

</style>
