<template>
  <SelectText class="performerfilter" v-model="performer" tooltext="Фильтр по исполнителю" :options="[...globalPerformers, '']"/>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectText from './ui/SelectText.vue';
export default {
  components: { SelectText },
  computed: {
    ...mapGetters(['globalPerformers']),
    performer:{
      get(){
        return this.$store.state.performer
      },
      set(v){
        this.$store.commit('setPerformer', v)
      }
    }
  },
  watch: {
    globalPerformers(v) {
      if (!this.performer)
        return
      if (!v?.length || !v.includes(this.performer))
        this.performer = null
    }
  }
}
</script>

<style lang="sass">
.performerfilter
  margin-left: auto
  width: fit-content
  text-align: center
  min-width: fit-content
  >.imagedthing
    background: colors.$darkdark !important
    transition: background .1s ease-out !important
    box-shadow: 0px 3px 5px rgba(0,0,0,.3) !important
    &:hover
      background: colors.$darkgreen2 !important

  .options-dropdown
    overflow: visible !important
    height: 0 !important
    position: static !important
    .imagedthing
      &:last-child
        border-radius: 0 0 10px 10px !important

  .imagedthing
    min-width: fit-content
    white-space: nowrap
    min-height: 39.19px !important
    height: 39.19px !important
    
      
</style>