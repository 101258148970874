<template>
  <LoadScreen />
  <router-view v-if="Role != null" v-slot="{ Component }">
    <transition name="fade" mode="ease-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import LoadScreen from './views/LoadScreen.vue'
import { mapState } from 'vuex'

export default {
  components: { LoadScreen },
  data: () => ({
    inited: false,
    inithash: ''
  }),
  async created() {
    this.inithash = window.location.hash
    let base = window.location.origin
    if (process.env.NODE_ENV == 'development') {
      console.log("DEV_MODE")
      base = base.replace('8080','3250')
    }
    this.$store.commit('setUrl', base + '/')
  },
  beforeMount(){
    //this.$store.commit('setReadedComments', window.localStorage.get('comments'))
  },
  mounted() {
    this.$store.dispatch('checkAuth')
    document.head.querySelector('title').innerHTML = 'Trello'
    setTimeout(() => {
      if (!this.inited)
        this.init()
    }, 100)
  },
  methods: {
    init() {
      this.inited = true
      if (this.Role) {
        if (!this.$route.path.match(/\/app/)) {
          this.$router.push('/app')
        }
        this.$store.dispatch('getProfile')
      }
      else
        this.$router.push('/login')
    }
  },
  computed: {
    ...mapState(['Role', 'workspace', 'wlist', 'tag','tasks'])
  },
  watch: {
    $route(to) {
      if (to.hash) {
        this.$store.commit('setHash', to.hash)
        if (this.tag) {
          let target = this.tasks.find(el => to.hash == ('#' + el._id))
          if (target && !target.tags.includes(this.tag))
            this.$store.commit('setTag', null)
        }
      }
    },
    Role() {
      this.init()
    },
    async workspace(v) {
      if (v) {
        this.$store.dispatch('getColumns')
        this.$store.dispatch('getTasks')
        this.$router.push(`/app/${encodeURIComponent(v._id)}` + window.location.hash)
      } else {
        this.$router.push(`/app/`)
      }
    },
    async '$route.params.workspace'(v) {
      if (!v && this.workspace) {
        this.$store.commit('setWorkspace', null)
      }
      if (v && (this.workspace?._id != v)) {
        while (!this.wlist)
          await new Promise(r => setTimeout(r, 50))
        if (this.wlist.find(el => el.id == v))
          this.$store.dispatch('getWorkspace', { id: v })
        else {
          this.$router.push(`/app/`)
        }
      }
    }
  }
}
</script>

<style lang="sass">
@use './sass/normalize.sass'
body
  min-width: 100%
  width: fit-content
  color: colors.$dark

  *, *::after, *::before
    box-sizing: border-box
    font-family: 'Open Sans', sans-serif

.fade-enter-active, .fade-leave-active
  transition: opacity .4s ease
.fastfade-enter-active, .fastfade-leave-active
  transition: opacity .2s ease-out
.fade-enter-from, .fade-leave-to,.fastfade-enter-from, .fastfade-leave-to
  opacity: 0
  user-select: none
html
  min-height: 100%
  scrollbar-width: thin

html, body, #app, .main
  display: flex
  flex-direction: column
  flex-grow: 1
.dimtext
  color: colors.$dim !important
.minititle
  font-weight: 500
  font-size: 20px
  margin-top: 5px
.w100
  width: 100%
.mb10
  margin-bottom: 10px
.mb20
  margin-bottom: 20px
.cont
  --padding: 20px
  padding: var(--padding)
  background: colors.$transi
  border-radius: 10px
#app
  .ql-toolbar.ql-snow, .ql-container.ql-snow
    border: none
  .ql-toolbar.ql-snow
    padding: 8px 2px
  .ql-editor.ql-blank:before
    color: colors.$dim
  .ql-editor
    h1, h2, h3, h4, h5, h6, p, ul, ol
      margin: .3em 0
    li
      margin: .15em 0
    *
      word-break: break-word !important
  .ql-snow a
    cursor: pointer
    color: colors.$lightgreen !important
    text-overflow: ellipsis
    overflow: hidden
    display: inline-block
    white-space: nowrap
    max-width: 100%

  .ql-snow.ql-toolbar button:hover, .ql-snow.ql-toolbar button.ql-active
    background: none !important
    svg .ql-stroke
      stroke: colors.$lightgreen !important
    svg .ql-fill
      fill: colors.$lightgreen !important
  .ql-toolbar.ql-snow .ql-formats
    margin-right: 0
  .ql-editor
    padding: 0 8px 8px
    ol, ul
      padding-left: .6em
body
  margin: 0
  padding: 0
  *
    line-height: 1.2
  ul, li
    margin: 0
    padding: 0
    list-style-type: none
  button, input
    border: none
    outline: none
  input[type="text"], textarea
    border: none
    background: white
    border-radius: 10px
    padding: 5px 10px
    font-size: 16px
    font-weight: 500
  button
    background: none
    cursor: pointer
  svg, img
    display: block
.hovered:hover
  background: colors.$red !important
.hovergreen:hover
  background: colors.$green2 !important
</style>
