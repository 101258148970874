<template>
  <input class="input" type="text" v-model="val">
</template>

<script>
  export default {
    props: {
      modelValue: {type: String}
    },
    computed: {
      val: {
        get(){
          return this.modelValue
        },
        set(v) {
          this.$emit('update:modelValue',v)
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
.input
  --color: #{colors.$darkgreen}
  color: var(--color)
  &::placeholder
    color: var(--color)
    opacity: .8
  display: block
  width: 100%
  border-radius: 10px
  padding: 5px 10px
  background: white
  font-size: 16px
</style>