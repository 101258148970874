<template>
  <component class="imagedthing" :class="{ 'filled': filled }" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    filled: { type: Boolean },
    tag: { type: String, default: 'div' }
  },
}
</script>

<style lang="sass">
.imagedthing
  min-height: 60px
  position: relative
  display: flex
  align-items: center
  padding: 10px
  gap: 10px
  cursor: pointer
  background: colors.$transi
  border-radius: 10px
  transition: background .1s ease-out
  outline: none
  &.filled:nth-child(n)
    background: transparentize(colors.$darkgreen, .45)
    min-height: 56px
    padding: 8px

  &:hover, &:focus-visible
    background: colors.$darkgreen2
  
  img
    width: 40px
    height: 40px
    object-fit: cover
    border-radius: 10px

  // Assuming these styles are for the text inside the .selected-option
  span
    color: #fff
    white-space: nowrap
    font-size: 14px
    font-weight: 500
</style>