<template>
  <div class="column columncreator">
    <form @submit.prevent ref="form" @click="$refs.input.click()" tabindex="0">
      <input class="columncreator__input" type="text" v-model="name" ref="input">
      <h3 class="minititle">Доступ:</h3>
      <label tabindex="0" class="columncreator__label">
        <input type="checkbox" value="performer" v-model="access">
        <span>Исполнитель</span>
      </label>
      <label tabindex="0" class="columncreator__label">
        <input type="checkbox" value="manager" v-model="access">
        <span>Менеджер</span>
      </label>
      <h3 class="minititle">Видимость:</h3>
      <label tabindex="0" class="columncreator__label">
        <input type="checkbox" value="performer" v-model="visible">
        <span>Исполнитель</span>
      </label>
      <label tabindex="0" class="columncreator__label">
        <input type="checkbox" value="manager" v-model="visible">
        <span>Менеджер</span>
      </label>
      <h3 class="minititle">Дополнительно:</h3>
      <label tabindex="0" class="columncreator__label">
        <input type="checkbox" value="archive" v-model="archive">
        <span>Архивный</span>
      </label>
      <Btn class="columncreator__create" @click="createColumn">Создать</Btn>
    </form>
    <button class="columncreator__btn" @click.stop.prevent="this.$refs.input.focus()">
      <svg class="columncreator__btn-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M6 12H18M12 6V18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
      </svg>
      <div class="columncreator__btn-title">Добавить столбец</div>
    </button>
  </div>
</template>

<script>
import Btn from './buttons/Btn.vue';

export default {
  data: () => ({
    name: '',
    access: [],
    visible: [],
    archive: false
  }),
  methods: {
    async createColumn() {
      let s = this.$store;
      let state = s.state
      s.commit('toggleLoad', 'createColumn');
      await fetch(state.url + `column`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: this.name, access: this.access, visible: this.visible, ws: state.workspace._id, archive: this.archive })
        })
        .then(res => {
          if (!res.ok) throw (res.status)
          s.dispatch('getWorkspace', { id: state.workspace._id })
          s.dispatch('getColumns')
          this.name = ''
          this.access = [],
          this.visible = []
          document.activeElement.blur()
        }).catch(err => {
          console.error(err)
        })
      s.commit('toggleLoad', 'createColumn');
    }
  },
  components: { Btn }
}
</script>

<style lang="sass">
.column
  display: grid
  grid-template-columns: 1fr
  gap: 10px
.columncreator
  position: relative
  cursor: pointer
  border-radius: 10px
  order: 10000
  background: transparentize(colors.$darkgreen, .45)
  padding: 10px
  /*
    &::before, &::after
      content: ''
      position: absolute
      top: 0
      transform: translateX(-1px)
      width: 2px
      left: 0
      height: 100%
      //background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAyCAQAAADcxcICAAAAAXNSR0IArs4c6QAAABFJREFUeNpj+A8EDCQRwwQAAAffJ9l3ztL6AAAAAElFTkSuQmCC')
      background-repeat: repeat
      background-size: contain
      background-position-y: 0
    &::after
      left: 100%
  &__label
    user-select: none
    cursor: pointer
    display: flex
    gap: 5px 
    align-items: center
  form
    color: white
    display: flex
    flex-direction: column
    gap: 15px
    opacity: 0
    transition: opacity .3s ease-out
    >*
      width: 100%
  &__create
    margin-top: 20px
  &__btn
    pointer-events: auto
    position: absolute
    z-index: 10
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px
    border-radius: 10px
    svg
      stroke: colors.$lighest
      transition: transform .3s ease-out, opacity .3s ease-out
    &-title
      transition: transform .3s ease-out, opacity .3s ease-out
      margin-top: -30px
      font-weight: 700
      color: white
      text-align: center
      font-size: 24px
  
  form:focus-within 
    opacity: 1
    pointer-events: auto
  form:focus-within~&__btn
    pointer-events: none
    svg
      transform: translateX(var(--colwidth))
      opacity: 0
      pointer-events: none
    .columncreator__btn-title
      transform: translateX(calc(var(--colwidth) * (-1)))
      opacity: 0
      pointer-events: none

</style>