<template>
  <div class="cheques">
    <div class="cheques__bar">
      <div class="cheques__modes">
        <Btn :small="true" class="cheques__modes-btn" @click="mode = 0" :class="{ 'active': mode == 0 }">От меня</Btn>
        <Btn :small="true" class="cheques__modes-btn" @click="mode = 1" :class="{ 'active': mode == 1 }">Выписаные мне</Btn>
        <Btn :small="true" class="cheques__modes-btn" @click="mode = 2" :class="{ 'active': mode == 2 }">Создать чек</Btn>
      </div>
    </div>
    <div class="cheques__chequeswrap" v-if="mode != 2">
      <div class="cheques__cheques">
        <Cheque @update="getCheques" v-for="cheque in notPaidCheques" :cheque="cheque" :key="cheque._id" />
        <div v-if="!loading && !notPaidCheques.length">Неоплаченные чеки отсутствуют</div>
      </div>
      <div class="cheques__chequebar">
        <div class="cheques__chequebar-title">История</div>
        <Cheque v-for="cheque in PaidCheques" :cheque="cheque" :key="cheque._id" />
        <div v-if="!loading && !PaidCheques.length">История пуста</div>
      </div>
    </div>
    <div class="cheques__create" v-else>
      <div class="cheques__tasks">
        <div class="cheques__task" v-for="task in tasks" :key="task._id" :class="{ active: selected[task._id] }" @click="selected[task._id] = !selected[task._id]">
          <TaskViewer :task="task" :column="null" :draggable="false" :chequeMode="true" />
        </div>
        <div v-if="!loading && !tasks.length">Нет неоплаченных выполненных заданий</div>
      </div>
      <div class="cheques__create-line">
        <Btn @click="popupShown = 1" :disabled="!selectedCount">Создать чек</Btn>
      </div>
    </div>
    <Popup v-model="popupShown">
      <div class="cheques__popup">
        <Select class="mb10" @keydown="handleKeyDown" @keyup="handleKeyUp" :options="alsoMe ? workspace_userlist : notme" v-model="payer" tooltext="Кто оплачивает?" />
        <div class="cheques__sum mb10">
          <div class="cheques__sum-text">Сумма:</div>
          <div class="cheques__sum-value">{{ total || 0 }} ₽</div>
        </div>
        <Btn @click="createCheque" class="w100">Создать</Btn>
      </div>
    </Popup>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Btn from './buttons/Btn.vue';
import Cheque from '@/components/Cheque.vue'
import TaskViewer from './TaskViewer.vue';
import Select from './ui/Select.vue';
import Popup from './ui/Popup.vue';

export default {
  components: { Btn, Cheque, TaskViewer, Select, Popup },
  data: () => ({
    popupShown: false,
    mode: 0,
    payer: null,
    loading: false,
    cheques: [],
    tasks: [],
    selected: {},
    alsoMe: false,
    keysPressed: {}
  }),
  computed: {
    ...mapState(['url', 'workspace']),
    ...mapGetters(['notme', 'workspace_userlist']),
    notPaidCheques() {
      return this.cheques.filter(el => !el.paid)
    },
    PaidCheques() {
      return this.cheques.filter(el => el.paid)
    },
    selectedCount() {
      return Object.values(this.selected).filter(el => el).length
    },
    total() {
      let total = 0
      for (let task of this.tasks)
        if (this.selected[task._id])
          total += task.price
      return total
    }
  },
  mounted() {
    this.getCheques()
  },
  methods: {
    checkCombination() {
      if (
        this.keysPressed['Control'] &&
        this.keysPressed['Shift'] &&
        this.keysPressed['Alt'] &&
        this.keysPressed['Backspace']
      ) {
        this.alsoMe = !this.alsoMe;
      }
    },
    handleKeyDown(event){
      this.keysPressed[event.key] = true;
      this.checkCombination();
    },
    handleKeyUp (event) {
      this.keysPressed[event.key] = false;
    },
    async getCheques() {
      this.loading = true
      await fetch(this.url + `cheques?ws=${encodeURIComponent(this.workspace._id)}`, { credentials: 'include' })
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          return res.json()
        }).then(json => this.cheques = json[(this.mode == 0) ? 'my' : 'tome'])
        .catch(err => { console.error(err); alert(err); })
      this.loading = false
    },
    async getTasks() {
      this.loading = true
      await fetch(this.url + `notpaid?ws=${encodeURIComponent(this.workspace._id)}`, { credentials: 'include' })
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          return res.json()
        }).then(json => { console.log(json); this.tasks = json })
        .catch(err => { console.error(err); alert(err); })
      this.loading = false
    },
    async createCheque() {
      await fetch(this.url + 'cheque', {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
          payer: this.payer,
          ws: this.workspace._id,
          ids: this.tasks.map(task => task._id).filter(id => this.selected[id]),
          sum: this.total
        })
      }).then(async res => {
        if (!res.ok)
          throw (await res.text())
        this.popupShown = false
      }).catch(err => {
        alert(err); console.error(err)
      })
      await this.getTasks()

    }
  },
  watch: {
    mode(v, old) {
      if (v != old) {
        if (v == 2)
          this.getTasks()
        else
          this.getCheques()
      }
    }
  }
}
</script>

<style lang="sass">
.cheques
  display: flex
  flex-grow: 1
  gap: 30px
  &__chequebar
    width: 220px
    flex-shrink: 0
    >*
      margin-bottom: 15px
    &-title
      color: white
      background: colors.$transi
      padding: 10px 20px
      border-radius: 10px
      font-weight: 700
      font-size: 18px
      text-align: center
  &__chequeswrap
    display: flex
    gap: 20px
    flex-grow: 1
  &__cheques
    flex-grow: 1
    display: flex
    flex-wrap: wrap
    gap: 15px
    align-items: flex-start
    align-content: flex-start
  &__label
    font-size: 16px
    margin-bottom: 10px
  &__bar
    width: 150px
    flex-shrink: 0
  &__tasks
    display: flex
    flex-wrap: wrap
    gap: 30px
    align-items: flex-start
    position: absolute
    padding: 10px 10px 40px
    margin: -10px
    overflow-y: auto

    max-height: calc(100% - 50px)
  &__task
    max-width: 300px
    *
      user-select: none !important
    cursor: pointer
    color: white
    border-radius: 10px
    &.active
      outline: 3px solid colors.$lightgreen
  &__create
    flex-grow: 1
    position: relative
    &-line
      width: 100%
      position: sticky
      top: calc(100% - 50px)
      height: 50px

      >*
        width: 100%
        height: 100%
  &__modes
    display: grid
    grid-template-columns: 1fr
    gap: 10px
    &-btn
      height: 35px
      &.active
        background: mix(colors.$transi, colors.$lightgreen, 50%)
  &__sum
    background: colors.$transi
    padding: 10px
    border-radius: 10px
    display: flex
    justify-content: space-between
    font-size: 16px
    &-value
      font-weight: 500
      color: colors.$gold
  
</style>