<template>
  <div class="main">
    <div class="main__line">
      <ProfileSquare />
      <div class="line line_nomargin line_thin"></div>
      <WorkspaceSquare v-for="w in wlist" :key="w.id" :workspace="w"/>
      <AddWorkspaceSquare v-if="Role == 2"/>
    </div>
    <Workspace class="main__ws"/>
    <!--
    <button popovertarget="info" id="btn">button</button>
    <div popover anchor="btn" id="info">
      <ul>
        <li>menu</li>
        <li>of</li>
        <li>the</li>
        <li>workspace</li>
      </ul>
    </div>
  -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AddWorkspaceSquare from '@/components/AddWorkspaceSquare.vue';
import ProfileSquare from '@/components/ProfileSquare.vue';
import WorkspaceSquare from '@/components/WorkspaceSquare.vue';
import Workspace from '@/components/Workspace.vue';

export default {
    name: 'Main',
    components: { ProfileSquare, WorkspaceSquare, AddWorkspaceSquare, Workspace },
    mounted() {
      this.$store.dispatch('getWlist')
    },
    computed: {
      ...mapState(['wlist','Role'])
    }
    
}
</script>
<style lang="sass">
.line
  width: 100%
  background: colors.$transparent
  height: 1px
  margin: 10px 0
  &_nomargin
    margin: 0
  &_thin
    width: calc(100% - 10px)
    margin-left: auto
    margin-right: auto
.main
  --pad: 10px
  padding: var(--pad)
  gap: 20px
  display: flex
  flex-direction: row !important
  align-items: flex-start
  color: colors.$dark
  position: relative
  //min-height: 100vh
  background: linear-gradient(90deg, rgba(255,255,255,.8) 0%, rgba(255,255,255,.8) 100%),url('~@/assets/img/bg.webp')
  background-size: cover
  background-attachment: fixed
  input, textarea, select
    outline: none
    &:focus-visible
      border-color: black
  &__ws
    display: flex
    flex-direction: column
    flex-grow: 1
    align-self: stretch
  &__line
    position: relative
    z-index: 50
    display: grid
    grid-template-columns: 1fr
    gap: 10px
</style>