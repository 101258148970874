<template>
  <button class="cb">
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
  </button>
</template>

<script>
  export default {
    
  }
</script>

<style lang="sass" scoped>
.cb
  cursor: pointer
  color: colors.$lightgreen
  background: colors.$darkgreen
  border-radius: 10px
  display: flex
  align-items: center
  justify-content: center
  --size: 30px
  --svgsize: 22px
  width: var(--size)
  height: var(--size)
  transition: background .1s ease-out
  &:hover
    background: colors.$darkgreen2
  svg
    stroke-width: 3
    width: var(--svgsize)

</style>