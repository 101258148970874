<template>
  <Teleport to="body">
    <div class="popup" :class="{ 'active': modelValue }" @click="$emit('update:modelValue', false)">
      <div class="popup__body" @click.stop>
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    modelValue: { type: Boolean, default: false }
  }
}
</script>

<style lang="sass">
.popup
  color: white
  opacity: 0
  pointer-events: none
  z-index: 1000
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 20px
  transition: .3s ease-out
  background: rgba(255,255,255,.2)
  backdrop-filter: blur(3px)
  &.active
    opacity: 1
    pointer-events: auto
  &.active &__body
    transform: none
  &__body
    min-width: 300px
    padding: 20px
    margin: auto
    border-radius: 10px
    background: transparentize(colors.$calm, .15)
    transition: .3s ease-out
    transform: translateY(40px)

</style>