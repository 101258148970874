<template>
  <Btn color="dark" @click="active = !active"><svg class="manageIcon" viewBox="0 0 278.907 278.907">
      <path d="M39.749 168.196c3.313 0 6-2.687 6-6V147.64h87.705v14.557c0 3.313 2.687 6 6 6s6-2.687 6-6V147.64h87.705v14.557c0 3.313 2.687 6 6 6s6-2.687 6-6V141.64c0-3.313-2.687-6-6-6h-93.705v-14.176c0-3.313-2.687-6-6-6s-6 2.687-6 6v14.176H39.749c-3.313 0-6 2.687-6 6v20.557a6 6 0 0 0 6 5.999z" />
      <use xlink:href="#B" />
      <path d="M159.504 233.04h-40.101c-10.862 0-19.698 8.836-19.698 19.698v14.801c0 3.313 2.687 6 6 6h67.497c3.313 0 6-2.687 6-6v-14.801c0-10.862-8.836-19.698-19.698-19.698zm-47.799 19.698a7.71 7.71 0 0 1 7.698-7.698h14.05v16.499h-21.749v-8.801zm55.498 8.801h-21.749V245.04h14.05a7.71 7.71 0 0 1 7.698 7.698v8.801zM139.454 57.866c14.474 0 26.249-11.775 26.249-26.249S153.927 5.368 139.454 5.368s-26.249 11.775-26.249 26.249 11.775 26.249 26.249 26.249zm0-40.498a14.27 14.27 0 0 1 14.249 14.249 14.27 14.27 0 0 1-14.249 14.249 14.27 14.27 0 0 1-14.249-14.249 14.27 14.27 0 0 1 14.249-14.249z" />
      <path d="M105.705 103.478h67.497c3.313 0 6-2.687 6-6v-14.8c0-10.862-8.836-19.698-19.698-19.698h-40.101c-10.862 0-19.698 8.836-19.698 19.698v14.8a6 6 0 0 0 6 6zm61.497-20.8v8.8h-21.749V74.979h14.05a7.71 7.71 0 0 1 7.699 7.699zm-55.497 0a7.71 7.71 0 0 1 7.698-7.698h14.05v16.499h-21.749v-8.801z" />
      <use xlink:href="#B" x="99.705" />
      <path d="M259.209 233.04h-40.101c-10.861 0-19.698 8.836-19.698 19.698v14.801c0 3.313 2.687 6 6 6h67.497c3.313 0 6-2.687 6-6v-14.801c0-10.862-8.836-19.698-19.698-19.698zm-47.798 19.698a7.71 7.71 0 0 1 7.698-7.698h14.05v16.499h-21.748v-8.801zm55.496 8.801h-21.749V245.04h14.05a7.71 7.71 0 0 1 7.698 7.698v8.801zM39.749 227.927c14.474 0 26.249-11.775 26.249-26.249s-11.775-26.249-26.249-26.249S13.5 187.205 13.5 201.678s11.775 26.249 26.249 26.249zm0-40.498a14.27 14.27 0 0 1 14.249 14.249 14.27 14.27 0 0 1-14.249 14.249A14.27 14.27 0 0 1 25.5 201.678a14.27 14.27 0 0 1 14.249-14.249zm20.05 45.611H19.698C8.836 233.04 0 241.876 0 252.738v14.801c0 3.313 2.687 6 6 6h67.497c3.313 0 6-2.687 6-6v-14.801c0-10.862-8.837-19.698-19.698-19.698zM12 252.738a7.71 7.71 0 0 1 7.698-7.698h14.05v16.499H12v-8.801zm55.497 8.801H45.749V245.04h14.05a7.71 7.71 0 0 1 7.698 7.698v8.801z" />
      <defs>
        <path id="B" d="m113.205,201.678c0,14.474 11.775,26.249 26.249,26.249s26.249-11.775 26.249-26.249-11.775-26.249-26.249-26.249-26.249,11.776-26.249,26.249zm40.498,0c0,7.857-6.392,14.249-14.249,14.249s-14.249-6.392-14.249-14.249 6.392-14.249 14.249-14.249 14.249,6.392 14.249,14.249z" />
      </defs>
    </svg></Btn>
  <Popup v-model="active">
    <Select class="mb20" :disabled="localLoading" :options="notme" v-model="performer" tooltext="Выберите пользователя" />
    <div class="cont mb20">
      <RadioGroup :disabled="!performer" title="Роль" class="radiogroup_2" :options="[{ value: 'performer', text: 'Исполнитель' }, { value: 'manager', text: 'Менеджер' }]" v-model="localRole" />
    </div>
    <Btn class="w100" :disabled="localLoading || !localRole || !performer" @click="editUser">
      <Loader v-if="localLoading" class="btn__loader" />
      <span :style="{ 'visibility': localLoading ? 'hidden' : 'visible' }">Сохранить</span>
    </Btn>
  </Popup>
</template>

<script>
import Btn from './buttons/Btn.vue';
import Loader from './ui/Loader.vue';
import Popup from './ui/Popup.vue';
import RadioGroup from './ui/RadioGroup.vue';
import Select from './ui/Select.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  components: { Btn, Popup, Select, RadioGroup, Loader },
  data: () => ({
    active: false,
    performer: null,
    localRole: null,
    localLoading: false
  }),
  computed: {
    ...mapState(['url', 'workspace']),
    ...mapGetters(['notme', 'userMap']),
  },
  methods: {
    async editUser() {
      if (!this.localRole)
        return
      this.localLoading = true
      await fetch(this.url + 'editUser', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ws: this.workspace._id, user: this.performer, localRole: this.localRole })
      })
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          await this.$store.dispatch('getWorkspace', { id: this.workspace._id, silent: true })
        })
        .catch(err => { console.error(err); alert(err) })
      this.localLoading = false
    }
  },
  watch: {
    performer(v) {
      this.localRole = this.userMap[v]?.localrole
    }
  }
}
</script>

<style lang="sass" scoped>
.manageIcon
  width: 24px
  fill: colors.$lighest
  transform: scale(1.3, 1.2)
</style>