import { createStore } from 'vuex'
import { nextTick } from 'vue';

export default createStore({
  state: {
    url: '',
    hash: '',
    loads: {},
    profile: {},
    wlist: null,
    columns: [],
    tasks: [],
    comments: [],
    readedcomments: {},
    globalTime: null,
    workspace: null,
    Role: null,
    localRole: null,
    tag: null,
    performer: null
  },
  mutations: {
    setHash(state, hash) {
      state.hash = hash
    },
    setTag(state, tag) {
      state.tag = tag
    },
    setPerformer(state, performer) {
      state.performer = performer
    },
    setWorkspace(state, ws) {
      state.workspace = ws
      state.localRole = ws?.localrole
      if (!ws) {
        state.columns = []
        state.tasks = []
        state.localRole = null
      }
    },
    setWlist(state, list) {
      state.wlist = list
    },
    setProfile(state, p) {
      state.profile = p
      if (p) {
        if (!window.localStorage.getItem('comments' + p._id))
          window.localStorage.setItem('comments' + p._id, JSON.stringify({}))
        state.readedcomments = JSON.parse(window.localStorage.getItem('comments' + p._id))
      }
    },
    setColumns(state, cols) {
      state.columns = cols
      if (!cols || cols.length == 0)
        state.tasks = []
    },
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    setComments(state, comments) {
      state.comments = comments
    },
    setReadedComments(state, comments) {
      state.readedcomments = comments
    },
    setUrl(state, url) {
      state.url = url//.replace('8080', '3250')
    },
    toggleLoad(state, name) {
      if (state.loads[name])
        delete state.loads[name]
      else
        state.loads[name] = true
    },
    setRole(state, Role) {
      state.Role = Role
    },
  },
  actions: {
    reset({ commit }) {
      commit('setRole', false)
      commit('setWlist', [])
      commit('setWorkspace', null)
    },
    async checkAuth({ state, commit }) {
      commit('toggleLoad', 'checkAuth')
      await fetch(state.url + 'auth', { credentials: 'include' })
        .then(res => {
          if (!res.ok) return commit('setRole', false)
          return res.text()
        }).then(role => {
          commit('setRole', role || false)
        }).catch(err => {
          console.error(err)
          commit('setRole', false)
        })
      commit('toggleLoad', 'checkAuth')
    },
    updateSeen({ state }) {
      window.localStorage.setItem('comments' + state.profile._id, JSON.stringify(state.readedcomments))
    },
    async getWlist({ state, commit }) {
      commit('toggleLoad', 'getWlist')
      await fetch(state.url + 'wlist', { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.status)
          return res.json()
        }).then(list => {
          commit('setWlist', list)
          if (state.workspace && !list.find(el => el.id == state.workspace._id))
            commit('setWorkspace', null)
        }).catch(err => {
          console.error(err)
        })
      commit('toggleLoad', 'getWlist')
    },
    async getProfile({ state, commit }) {
      if (!state.Role)
        return
      commit('toggleLoad', 'getProfile')

      await fetch(state.url + `profile`, { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.status)
          return res.json()
        }).then(p => {
          commit('setProfile', p)
        }).catch(err => {
          console.error(err)
        })
      commit('toggleLoad', 'getProfile')
    },
    async getWorkspace({ state, commit }, { id, silent }) {
      if (!id)
        return console.error('getWorkspace: id не указан')

      if (!silent) {
        commit('toggleLoad', 'getWorkspace')
      }
      await fetch(state.url + `workspace?ws=${encodeURIComponent(id)}`, { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.status)
          return res.json()
        }).then(ws => {
          if (!silent && (ws._id != state.workspace?._id)) {
            commit('setColumns', [])
          }
          commit('setWorkspace', ws)
        }).catch(err => {
          console.error(err)
        })
      if (!silent) {
        commit('toggleLoad', 'getWorkspace')
      }
    },
    async getColumns({ state, commit }) {
      if (!state.workspace?._id)
        return commit('setColumns', [])
      //commit('toggleLoad', 'getColumns')
      await fetch(state.url + `cols?ws=${encodeURIComponent(state.workspace._id)}`, { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.status)
          return res.json()
        }).then(cols => {
          commit('setColumns', cols)
        }).catch(err => {
          console.error(err)
          alert(err)
        })
      //commit('toggleLoad', 'getColumns')
    },
    async getComments({ state, commit }) {
      await fetch(state.url + `comments?ws=${encodeURIComponent(state.workspace._id)}`, { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.status)
          return res.json()
        }).then(comments => {
          commit('setComments', comments)
        }).catch(err => {
          console.error(err)
          alert(err)
        })
      console.log(state.comments)
    },
    async getTasks({ state, commit, dispatch }) {
      //commit('toggleLoad', 'getTasks')
      const yPos = pageYOffset
      await fetch(state.url + `tasks?ws=${encodeURIComponent(state.workspace._id)}`, { credentials: 'include' })
        .then(res => {
          if (!res.ok) throw (res.status)
          return res.json()
        }).then(tasks => {
          commit('setTasks', tasks)
          if (state.hash && state.tag) {
            let target = tasks.find(el => state.hash == ('#' + el._id))
            if (target && !target.tags.includes(state.tag))
              commit('setTag', null)
          }
          if (state.hash && state.performer) {
            let target = tasks.find(el => state.hash == ('#' + el._id))
            if (target && !target.performers.includes(state.performer))
              commit('setPerformer', null)
          }
        }).catch(err => {
          console.error(err)
          alert(err)
        }).finally(() => {
          nextTick(() => {
            if (pageYOffset != yPos)
              window.scrollTo({ top: yPos })
          })
        })
      await dispatch('getComments')
      //commit('toggleLoad', 'getTasks')
    },
  },
  getters: {
    performerId(state) {
      if (state.performer) {
        let found = state?.workspace?.userlist?.find(el => el.log == state.performer)
        if (!found)
          return ''
        return found._id
      }
    },
    globalPerformers(state) {
      let res = []
      if (!state.workspace?.userlist)
        return res
      return state.workspace.userlist.filter(el => el.localrole == 'performer').map(el => el.log)
    },
    globalTags(state) {
      let obj = {}
      for (let t of state.tasks)
        for (let tag of t.tags)
          obj[tag] = true
      obj[''] = true
      return Object.keys(obj)
    },
    loading(state) {
      return Object.keys(state.loads).length > 0
    },
    coltasks(state, getters) {
      let obj = {}
      if (!state.tasks?.length)
        return obj
      for (let task of state.tasks) {
        if (state.tag && !task.tags.includes(state.tag))
          continue
        if (state.performer && getters.performerId && !task.performers.includes(getters.performerId)) {
          console.log(state.performer)
          console.log(task)
          continue
        }
        if (task.workspace != state.workspace._id)
          continue
        if (!obj[task.column])
          obj[task.column] = [task]
        else
          obj[task.column].push(task)
      }
      return obj
    },
    workspace_userlist(state) {
      if (!state.workspace)
        return []
      return state.workspace.userlist
    },
    notme(state) {
      if (!state.workspace)
        return []
      return state.workspace.userlist.filter(el => el._id != state.profile._id)
    },
    columnMap(state) {
      let obj = {}
      let c = state.columns
      for (let i in c)
        obj[c[i]._id] = i
      return obj
    },
    reverseColumnMap(state) {
      let obj = {}
      let c = state.columns
      for (let i in c)
        obj[i] = c[i]._id
      return obj
    },
    userMap(state) {
      let obj = {}
      if (!state.workspace)
        return obj
      for (let u of state.workspace.userlist)
        obj[u._id] = u
      return obj
    },
    commentMap(state) {
      let obj = {}
      if (!state.comments)
        return obj
      for (let c of state.comments) {
        if (!obj[c.task])
          obj[c.task] = []
        obj[c.task].push(c)
      }
      return obj
    }
  }
})
