<template>
  <div class="column column_main" :class="{ 'column_nograb': !admin }">
    <div class="column__headline" @pointerdown="startdrag">
      <h2 class="column__title">{{ column.name }}</h2>
      <CloseBtn @pointerdown.stop class="column__delete" @click.stop="deleteColumn" v-if="admin" />
    </div>
    <TaskViewer :compact="column.archive" v-for="task in tasks" :key="task._id" :task="task" @dragstart.stop="(e) => taskDragStart(e, task)" :column="column" />
    <TaskCreator :column="column" v-if="!column.archive && ['director','manager'].includes(localRole)" />
  </div>
</template>

<script>
import TaskCreator from './TaskCreator.vue';
import TaskViewer from './TaskViewer.vue';
import CloseBtn from './buttons/closeBtn.vue'
import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    column: { type: Object, required: true }
  },
  methods: {
    taskDragStart(e, task) {
      e.dataTransfer.setData('application/json', JSON.stringify({ task_id: task._id }))
    },
    startdrag() {
      if (this.admin) {
        console.log('emitted')
        this.$emit('dragstarted')
      }
    },
    async deleteColumn() {
      if (!confirm("Удалить столбец " + this.column.name + '?'))
        return;
      let s = this.$store;
      let state = s.state;
      s.commit('toggleLoad', 'deleteColumn');
      await fetch(state.url + `column`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ col: this.column._id, ws: state.workspace._id })
      })
        .then(res => {
          if (!res.ok)
            throw (res.status);
          s.dispatch('getWorkspace', { id: state.workspace._id })
          s.dispatch('getColumns');
        }).catch(err => {
          console.error(err);
        });
      s.commit('toggleLoad', 'deleteColumn');
    }
  },
  components: { CloseBtn, TaskCreator, TaskViewer },
  computed: {
    ...mapState(['localRole']),
    ...mapGetters(['coltasks']),
    tasks() {
      return this.coltasks[this.column._id] || []
    },
    admin() {
      return this.localRole == 'director'
    }
  }
}
</script>

<style lang="sass">
.column
  --colwidth: 300px
  width: var(--colwidth)
  background: transparentize(colors.$darkgreen, .45)
  color: white
  align-content: start
  align-items: start
  border-radius: 10px
  --colpad: 15px
  padding: var(--colpad) var(--colpad) 0
  >*
    margin-bottom: 30px

  &_nograb &__headline
    cursor: auto
  &__headline
    order: -1000
    position: relative
    cursor: grab
    display: flex
    align-items: center
    gap: 15px
    user-select: none
    &:active
      cursor: grabbing
  &__delete
    cursor: auto
    align-self: flex-start
    margin-left: auto
    top: 0
    right: 0
    position: absolute
    opacity: .15
    transition: opacity .1s ease-out, background .1s ease-out !important
    &:hover
      opacity: 1
  &__title
    font-size: 22px
    color: white
    text-align: center
    font-weight: 700
  &:has(.taskcreator~.taskcreator) >.taskcreator:last-child
    display: none !important
</style>