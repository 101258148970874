<template>
  <div class="custom-select" :class="{'custom-select_expanded':dropdownVisible}">
    <select v-model="selectedValue" @change="updateValue($event.target.value)" class="hidden-select" tabindex="-1">
      <option v-for="option in options" :key="option" :value="option">
        {{ option  }}
      </option>
    </select>
    <ImagedThing class="selected-option" :filled="selectedValue? true : false" tabindex="0" @click="toggleDropdown" @keydown.space.prevent="toggleDropdown" @keydown.enter.prevent="toggleDropdown">
      <span>{{ tooltext + (selectedValue? `: ${selectedValue}`: '') }}</span>
    </ImagedThing>
    <div class="options-dropdown" v-show="dropdownVisible">
      <ImagedThing v-for="option in options" :key="option" class="option-preview" tabindex="0" @click="selectOption(option)" @keydown.space.prevent="selectOption(option)" @keydown.enter.prevent="selectOption(option)">
        <span>{{ option || '-все-'}}</span>
      </ImagedThing>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImagedThing from './ImagedThing.vue';

export default {
  name: 'CustomSelect',
  props: {
    tooltext: {
      type: String, default: 'Select an option'
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: false,
    }
  },
  data() {
    return {
      selectedValue: this.modelValue,
      dropdownVisible: false,
    };
  },
  computed: {
    ...mapState(['url']),
    selectedOption() {
      return this.options.find(option => option === this.selectedValue);
    }
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    selectOption(option) {
      this.selectedValue = option
      this.updateValue(option)
      this.dropdownVisible = false
    },
  },
  components: { ImagedThing }
}
</script>

<style lang="sass">

</style>
