<template>
  <div class="tagline">
    <span v-for="(tag, index) in tags" :key="index" class="tag">
      {{ tag }}
      <CloseBtn @click="$emit('removeTag', index)" v-if="deletable" class="remove-tag-btn" />
    </span>
  </div>
</template>

<script>
import CloseBtn from '../buttons/closeBtn.vue';

export default {
  components: { CloseBtn },
  props: {
    tags: { type: Array, default: () => [] },
    deletable: { type: Boolean, default: true }
  }
}
</script>

<style lang="sass">
.tag
  display: inline-flex
  align-items: center
  margin: 2px
  padding: 5px
  border-radius: 5px
  background: colors.$darkgreen
  color: colors.$lightgreen
  
.remove-tag-btn:nth-child(n)
  --size: 22px
  --svgsize: 20px
  margin-left: 3px
  cursor: pointer
  border-radius: 5px
  &:not(:hover)
    svg
      color: colors.$lighest
      stroke: colors.$lighest
</style>